/* eslint-disable no-undef */
import {
    dynLoad,
    allRoutes,
    scrollTo,
    getClosest,
    getTodayYMD,
    addCSRFToken,
    getUser,
    getQueryVariable,
    convertMetrics,
    convertMetricsLabels,
    isDefined,
} from '@app/core'
import { Cardline, Medical, Consent } from '@app/api'
import { Component } from '@app/utils'
import { t } from 'i18next'
import { h } from 'hyperapp'
import * as constants from '@app/constants'
import { getUnitFromType } from '@app/core/tools/metrics/getUnitFromType'
import { transformMetric } from '@app/core/tools/metrics/transformMetric'
import {
    defaultLocale,
    getLocalStoredLocale,
    getQueryLocale,
} from '@app/core/languagesHandler'
import { synchQueryLangToApi } from '@app/core/languagesSyncWithApi'
import { LocalesConversions } from '@app/core/tools/metrics'

export const helps = dynLoad('help-mssg', 'Onboarding/custo')
const HELPS_MESSAGES = helps.HELPS_MESSAGES

const HELP_DEFAULT = HELPS_MESSAGES.DEFAULT.label
const HELP_SLIDER = HELPS_MESSAGES.SLIDER.label
const HELP_MORAL = HELPS_MESSAGES.MORAL.label
const HELP_STRESS = HELPS_MESSAGES.STRESS.label
const HELP_TIREDNESS = HELPS_MESSAGES.TIREDNESS.label
const HELP_BUTTON = HELPS_MESSAGES.BUTTON.label
const HELP_QCM = HELPS_MESSAGES.QCM.label
const LABEL_ALCOOL = HELPS_MESSAGES.ALCOOL.label
const LABEL_TOBACCO = HELPS_MESSAGES.TOBACCO.label

const FIRST_CARD_NAME = 'EVAL-MORA-01'

require('navigator.sendbeacon')

const state = {
    idx: 0,
    activeValidation: false,
    steps: {},
    current: {},
    values: [],
    readed: [],
    skipped: [],
    formIsSubmitting: false,
    error: '',
    customer: 'betterise',
    endBoard: false,
    activeEndSubmit: false,
    targetCards: [1, 22, 58],
    lastanswercallback: null,
    maxCards: 10,
    totalCards: 0,
    user: null,
    uniformButtonHeight: 0,
    cerbaSportCoachDisplay: 'EVAL-CERBA-032',
    specialInitialCount: 0,
    locale: getLocalStoredLocale()?.value ?? defaultLocale,
    timelineId: null,
    alpha: 'abcdefghijklmnopqrstuvwxyz',
    mobileOnboarding: false,
    showPregnancyInputs: false,
    loaded: false,
    showPregnancyInputsStep: 'pregnancy',
    customDisplay: '',
    updateAnswer: null,
    onboardingEnd: false,
    medicalStudysurvey: null,
    healConsentDisplayed: false,
}

const actions = {
    onComponentUnload: (props) => (state, actions) => {
        //
    },
    onComponentRemove: (props) => (state, actions) => {
        //
    },
    onComponentBeforeRemove: (props) => (state, actions) => {
        //
    },
    onComponentCreate: (props) => (state, actions) => {
        let isMobile = true
        actions.setIsMobileOnboarding(isMobile)
        //
        let timelineId = getQueryVariable('timelineid', false)
        if (timelineId && timelineId != false) {
            actions.setTimelineId(timelineId)
        } else {
            timelineId = null
        }
        //
        let customDisplay = sessionStorage.getItem('customDisplay')
        if (isDefined(customDisplay) && customDisplay != false) {
            actions.setCustomDisplay(customDisplay)
        }
        let updateAnswer = sessionStorage.getItem('updateAnswer')
        if (isDefined(updateAnswer) && updateAnswer != false) {
            actions.setUpdateAnswer(updateAnswer)
        }
        let updateAnswerId = sessionStorage.getItem('updateAnswerId')
        if (isDefined(updateAnswerId) && updateAnswerId != false) {
            actions.setUpdateAnswerId(updateAnswerId)
        }

        if (isMobile === true) {
            actions.setConfirmQuitEvent()
        }

        const locale = getQueryLocale()
        ;(locale && locale !== state.locale
            ? synchQueryLangToApi()
            : Promise.resolve('nothing')
        ).then(() => {
            if (customDisplay === 'popup') {
                if (isDefined(updateAnswerId)) {
                    Medical.getDoctorMedicalDefaultAnswer(updateAnswerId).then(
                        (res) => {
                            actions.setUpdateAnswerExistingValues(
                                res.data.values
                            )
                            actions.afterGetUser({
                                sessionUser: null,
                                timelineId: timelineId,
                                customDisplay: customDisplay,
                                props: props,
                            })
                        }
                    )
                } else {
                    actions.afterGetUser({
                        sessionUser: null,
                        timelineId: timelineId,
                        customDisplay: customDisplay,
                        props: props,
                    })
                }
            } else {
                let userCallback = (sessionUser) => {
                    actions.afterGetUser({
                        sessionUser: sessionUser,
                        timelineId: timelineId,
                        customDisplay: customDisplay,
                        props: props,
                    })
                }
                getUser(userCallback, true, false)
            }
        })
    },
    afterGetUser:
        ({ sessionUser, timelineId, customDisplay, props }) =>
        (state, actions) => {
            if (sessionUser) {
                actions.setUser(sessionUser)
            }
            //
            let targetCards = [1, 22]
            if (props.displayedon && props.displayedon === 'timeline') {
                targetCards = 58
                actions.setTargetCards(targetCards)
            } else if (props.customer) {
                actions.setCustomer(props.customer)
            }
            //
            let medicalStudytoken = localStorage.getItem('medicalStudytoken')
            let medicalStudysurvey = localStorage.getItem('medicalStudysurvey')
            if (isDefined(medicalStudysurvey)) {
                actions.setMedicalStudysurvey(medicalStudysurvey)
            }
            //
            if (customDisplay === 'popup' && !isDefined(timelineId)) {
                Cardline.getDoctorSpecificStudyMedicalCards(
                    localStorage.getItem('idPatient'),
                    medicalStudysurvey,
                    false
                ).then((res) => {
                    actions.handleQueryResult({ res: res, props: props })
                })
            } else {
                if (isDefined(timelineId)) {
                    if (isDefined(medicalStudytoken)) {
                        if (isDefined(medicalStudysurvey)) {
                            Cardline.getSpecificStudyMedicalCards(
                                timelineId,
                                medicalStudytoken,
                                medicalStudysurvey,
                                false
                            ).then((res) => {
                                actions.handleQueryResult({
                                    res: res,
                                    props: props,
                                })
                            })
                        } else {
                            Cardline.getMedicalCards(
                                timelineId,
                                medicalStudytoken,
                                false
                            ).then((res) => {
                                let firstCard = res.data[0]
                                if (
                                    isDefined(firstCard) &&
                                    isDefined(
                                        firstCard.userMedicalStudySurveyId
                                    )
                                ) {
                                    actions.setMedicalStudysurvey(
                                        firstCard.userMedicalStudySurveyId
                                    )
                                }

                                actions.handleQueryResult({
                                    res: res,
                                    props: props,
                                })
                            })
                        }
                    } else {
                        actions.redirectToTimeline()
                    }
                } else {
                    Cardline.getCards(targetCards, false).then((res) => {
                        actions.getCardsCallback({ res: res, props: props })
                    })
                }
            }

            window.onkeyup = function (e) {
                var key = e.keyCode ? e.keyCode : e.which
                if (key === 13) {
                    if (
                        document
                            .querySelector('#autoScrollMarker')
                            ?.getAttribute('data-formtype') !== 'textarea'
                    ) {
                        actions.nextCard(false)
                    }
                }
            }
        },
    handleQueryResult:
        ({ res, props }) =>
        (state, actions) => {
            //
            if (isDefined(res.data)) {
                if (isDefined(res.data.code) && +res.data.code === 2304) {
                    actions.redirectToTimeline(true)
                    return false
                }
                //
                let totalCards = 0
                for (var card in res.data) {
                    if (
                        res.data[card] &&
                        isDefined(res.data[card].forms) &&
                        res.data[card].forms.length > 0
                    ) {
                        totalCards++
                    }
                }
                //
                if (totalCards === 0) {
                    actions.redirectToTimeline()
                    return false
                } else {
                    actions.setTotalCards(totalCards)
                    actions.getCardsCallback({ res: res, props: props })
                }
            }
        },
    getCardsCallback:
        ({ res, props }) =>
        (state, actions) => {
            let onboardLocale = state.locale
            if (props.locale) {
                onboardLocale = props.locale
                actions.setLocale(onboardLocale)
            }
            //
            if (res.error) {
                actions.redirectToLogin()
                return false
            } else {
                let atLeastOneCardNotAnswered = false
                if (res.data.length > 0) {
                    var cards = res.data.sort((x, y) => {
                        return x.name == FIRST_CARD_NAME
                            ? -1
                            : y.name == FIRST_CARD_NAME
                              ? 1
                              : 0
                    })
                    //
                    let returnedData = actions.parseAndPushCards(cards)
                    let stateSteps = returnedData.stateSteps
                    atLeastOneCardNotAnswered =
                        returnedData.atLeastOneCardNotAnswered
                    onboardLocale = returnedData.onboardLocale
                    actions.setLocale(onboardLocale)
                    actions.setSteps(stateSteps)
                }
                if (atLeastOneCardNotAnswered) {
                    actions.updateCurrentStep()
                    setTimeout(() => {
                        actions.setLoaded(true)
                    }, 200)
                } else {
                    if (props.displayedon !== 'timeline') {
                        localStorage.setItem(
                            'onboarding-viewed-' +
                                state.user?.id +
                                '-' +
                                getTodayYMD(),
                            true
                        )
                        actions.redirectToTimeline()
                        return false
                    } else {
                        if (
                            props.setNothingToSurvey &&
                            props.setNothingToSurvey !== null
                        ) {
                            props.setNothingToSurvey(true)
                        }
                    }
                }
            }
        },
    setConfirmQuitEvent: () => (state, actions) => {
        if (state.customDisplay === 'popup') {
            return false
        }
        //
        var myEvent = window.attachEvent || window.addEventListener
        var myEventDom = document.attachEvent || document.addEventListener
        var chkevent = window.attachEvent ? 'onbeforeunload' : 'beforeunload'
        // var isOnIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
        // chkevent = isOnIOS ? 'pagehide' : chkevent;
        myEvent(chkevent, actions.triggerConfirmQuit)
        myEvent('pagehide', actions.triggerConfirmQuit)
        myEvent('visibilitychange', actions.triggerConfirmQuitVisibilty)
        // myEvent('unload', actions.triggerConfirmQuit);
        // myEventDom('blur', actions.triggerBeaconQuit)
    },
    removeConfirmQuitEvent: () => (state, actions) => {
        var myEvent = window.detachEvent || window.removeEventListener
        var myEventDom = document.detachEvent || document.removeEventListener
        var chkevent = window.detachEvent ? 'onbeforeunload' : 'beforeunload'
        myEvent(chkevent, actions.triggerConfirmQuit)
        myEvent('pagehide', actions.triggerConfirmQuit)
        myEvent('visibilitychange', actions.triggerConfirmQuitVisibilty)
    },
    triggerConfirmQuitVisibilty: (e) => (state, actions) => {
        e.stopPropagation()
        e.preventDefault()
        if (document.visibilityState === 'visible') {
            //
        } else {
            actions.triggerBeaconQuit(e)
        }
    },
    triggerConfirmQuit: (e) => (state, actions) => {
        if (!state.activeEndSubmit) {
            // if its not final submit
            var confirmationMessage = t(
                'Cette page demande de confirmer sa fermeture ; des données saisies pourraient ne pas être enregistrées.',
                { ns: 'onboarding' }
            )
            ;(e || window.event).returnValue = confirmationMessage
            return confirmationMessage
        }
    },
    triggerBeaconQuit: (e) => (state, actions) => {
        if (state.customer === 'resilience' || state.customer === 'oncoflow') {
            // fetch(`${API_URL}/medical-study/cancel-answer/`, {
            //     method: 'POST',
            //     headers: new Headers({'X-Token': localStorage.getItem('apitoken'), 'Content-Type': 'application/json'}),
            //     body: JSON.stringify({'userMedicalStudySurvey': state.medicalStudysurvey, 'currentCard': state.idx, 'totalCards': state.totalCards}),
            //     keepalive: true
            // })
        }
    },
    parseAndPushCards: (cards) => (state, actions) => {
        let stateSteps = []
        let cardIdx = 0
        let onboardLocale = state.locale
        let atLeastOneCardNotAnswered = false
        //
        // cards.unshift(cards[0]);
        if (isDefined(state.updateAnswer)) {
            cards = cards.filter((f) => f.name === state.updateAnswer)
        }
        for (let card in cards) {
            if (cards[card].forms[0] === undefined) {
                continue
            }
            // TEMP
            // cards[card].type = 'bloodpressure';
            // cards[card].forms[0].type = 'bloodpressure';
            //
            let stepType = cards[card].forms[0].type.toLowerCase(),
                stepValues = cards[card].forms[0].datas.length,
                stepDatas = cards[card].forms[0].datas,
                stepTag = cards[card].forms[0].tag,
                stepRanges = cards[card].forms,
                stepHelp = HELP_DEFAULT,
                stepTheme = actions.getTheme(cards[card].theme),
                stepLabel = '',
                skippableEval = isDefined(cards[card].skippableEval)
                    ? cards[card].skippableEval
                    : true,
                missingTag = cards[card].forms.filter(
                    (f) => f.tag === null || f.tag === '' || f.tag === undefined
                )

            if (missingTag.length > 0) {
                continue
            }

            if (
                stepType === 'slider' ||
                stepType === 'counter' ||
                stepType === 'statcounter'
            ) {
                if (
                    stepValues > 11 ||
                    stepType === 'counter' ||
                    stepType === 'statcounter'
                ) {
                    if (stepType !== 'counter' && stepType !== 'statcounter') {
                        stepType = 'number'
                    }
                    stepHelp = HELP_SLIDER
                } else {
                    if (stepTheme === 'stress') {
                        stepHelp = HELP_STRESS
                    } else if (stepTheme === 'tiredness') {
                        stepHelp = HELP_TIREDNESS
                    } else if (
                        stepTheme === 'sport' ||
                        stepTheme === 'sleep' ||
                        stepTheme === 'activity'
                    ) {
                        stepHelp = HELP_BUTTON
                    } else if (stepTheme === 'shape') {
                        stepHelp = HELP_MORAL
                    }
                }
            } else if (stepType === 'button' || stepType === 'yesno') {
                stepDatas = cards[card].forms
                stepHelp = HELP_BUTTON
            } else if (stepType === 'qcm') {
                stepDatas = cards[card].forms
                stepHelp = HELP_QCM
            }

            if (stepTheme === 'alcool') {
                stepLabel = LABEL_ALCOOL
            } else if (stepTheme === 'tobacco') {
                stepLabel = LABEL_TOBACCO
            }
            if (cards[card].extra) {
                stepHelp = cards[card].extra
            }

            let linkPatt = /(?=\[\[)(.*?)(\]\])/g
            if (isDefined(stepHelp)) {
                stepHelp = stepHelp.replace(linkPatt, (patt) => {
                    patt = patt.replace(/\[\[|\]\]/g, '').split('|')
                    if (patt.length === 3 && patt[0] === 'link') {
                        return (
                            '<a target="_blank" href="' +
                            patt[1] +
                            '">' +
                            patt[2] +
                            '</a>'
                        )
                    }
                    return patt
                })
            }
            if (!isDefined(cards[card].state)) {
                cards[card].state = 0 // temp fixe for onco question 58
            }
            if (cards[card].state === 0) {
                stateSteps[cardIdx] = {
                    theme: stepTheme,
                    name: cards[card].name,
                    question:
                        cards[card].title !== null
                            ? cards[card].title
                            : cards[card].name,
                    help: stepHelp,
                    type: cards[card].type,
                    unit: getUnitFromType(
                        cards[card].forms?.[0]?.type?.toLowerCase()
                    ),
                    numCard: cardIdx + 1,
                    form: {
                        id: cards[card].id,
                        type: stepType,
                        values: stepValues,
                        datas: stepDatas,
                        tag: stepTag,
                        label: stepLabel,
                    },
                    original: cards[card],
                    idtimeline: cards[card].timeline,
                    stepRanges: stepRanges,
                    skippableEval: skippableEval,
                }
                cardIdx++

                atLeastOneCardNotAnswered = true
            }
        }

        return {
            stateSteps: stateSteps,
            atLeastOneCardNotAnswered: atLeastOneCardNotAnswered,
            onboardLocale: onboardLocale,
        }
    },
    onComponentUpdate: (props) => (state, actions) => {
        if (props.lastanswercallback) {
            actions.setLastAnswerCallback(props.lastanswercallback)
        }
    },
    setTargetCards: (newState) => (state) => ({
        targetCards: newState,
    }),
    setCustomer: (newState) => (state) => ({
        customer: newState,
    }),
    setTimelineId: (newState) => (state) => ({
        timelineId: newState,
    }),
    setIsMobileOnboarding: (newState) => (state) => ({
        mobileOnboarding: newState,
    }),
    setUser: (newState) => (state) => ({
        user: newState,
    }),
    setError: (newState) => (state) => ({
        error: newState,
    }),
    setTotalCards: (newState) => (state) => ({
        totalCards: newState,
    }),
    previousCard: () => (state, actions) => {
        let previousStep = state.idx - 1
        let previousCard = state.steps[previousStep]
        let toRemovePreviousCard = state.values.filter(
            (f) =>
                f.id === previousCard.form.id ||
                (Array.isArray(f) &&
                    isDefined(
                        f.filter((subf) => subf.id === previousCard.form.id)[0]
                    ))
        )[0]
        let toRemovePreviousReadedCard = state.readed.filter(
            (fid) =>
                fid === previousCard.form.id ||
                (Array.isArray(fid) &&
                    isDefined(
                        fid.filter((subf) => subf === previousCard.form.id)[0]
                    ))
        )[0]
        let toRemovePreviousSkippedCard = state.skipped.filter(
            (fid) =>
                fid === previousCard.form.id ||
                (Array.isArray(fid) &&
                    isDefined(
                        fid.filter((subf) => subf === previousCard.form.id)[0]
                    ))
        )[0]
        let newSteps = null
        if (isDefined(toRemovePreviousReadedCard)) {
            state.readed.pop()
        }
        //
        if (isDefined(toRemovePreviousSkippedCard)) {
            state.skipped.pop()
        }
        //
        if (isDefined(toRemovePreviousCard)) {
            if (state.values.length === 1) {
                state.values = []
            } else {
                state.values.pop()
            }
            //
            newSteps = state.steps.filter(
                (f) =>
                    !isDefined(f.parentId) ||
                    (isDefined(f.parentId) &&
                    Array.isArray(toRemovePreviousCard)
                        ? isDefined(
                              toRemovePreviousCard.filter(
                                  (fd) => fd.id !== f.parentId
                              )[0]
                          )
                        : f.parentId !== toRemovePreviousCard.id)
            )
            previousStep = state.values.length + state.skipped.length
        } else {
            //
        }
        //
        if (isDefined(newSteps)) {
            actions.setSteps(newSteps)
        }
        //
        actions.setUniformButtonHeight(0)
        actions.setIdx(previousStep)
        actions.switchValidationActivation(false)
        actions.updateCurrentStep()
    },
    nextCard: (force) => (state, actions) => {
        if (!force && !state.activeValidation) {
            actions.setError(
                t('Merci de renseigner une réponse.', { ns: 'onboarding' })
            )
            return false
        } else if (state.formIsSubmitting) {
            actions.setError(
                t('Le formulaire est en cours de traitement.', {
                    ns: 'onboarding',
                })
            )
            return false
        }

        if (force) {
            // readedTypes
        }

        var els = document.querySelectorAll('.btzCurrentEvaluationMarker')
        if (!force) {
            // when we press "passer", the data must not be added, even if user select an answer
            let isValid = actions.pushValues(els)
            if (!isValid) {
                return false
            }
        } else {
            if (+state.current.type !== 58) {
                state.readed.push(state.current.form.id)
            } else {
                state.skipped.push(state.current.form.id)
            }
        }

        let thenClear = () => {
            for (var elem in els) {
                if (els[elem].classList !== undefined) {
                    els[elem].classList.remove('active')
                    els[elem].classList.remove('btzCurrentEvaluationMarker')
                }
            }
            let targetEl = document.querySelector('div#autoScrollMarker')
            if (targetEl) {
                scrollTo(targetEl, 0, 160)
            }
        }

        actions.setError('')
        if (+state.idx + 1 >= Object.keys(state.steps).length) {
            let thenPostCardLine = () => {
                actions.sendCardline(state.current.idtimeline)
            }
            if (
                (isDefined(state.timelineId) ||
                    state.customDisplay === 'popup') &&
                !force
            ) {
                actions.sendMedicalCardline({
                    idtimeline: state.timelineId,
                    thenClear: thenClear,
                    thenPost: thenPostCardLine,
                })
            } else {
                thenPostCardLine()
            }
        } else {
            if (
                (isDefined(state.timelineId) ||
                    state.customDisplay === 'popup') &&
                !force
            ) {
                actions.sendMedicalCardline({
                    idtimeline: state.timelineId,
                    thenClear: thenClear,
                    thenPost: null,
                })
            } else {
                thenClear()
                actions.setUniformButtonHeight(0)
                actions.setIdx(state.idx + 1)
                actions.switchValidationActivation(false)
                actions.updateCurrentStep()
            }
        }
    },
    sendCardline: (idtimeline) => (state, actions) => {
        state.activeEndSubmit = true
        actions.setFormIsSubmitting(true)
        //
        let medicalDatas = {}
        medicalDatas.answers = state.values
            .reduce((acc, val) => acc.concat(val), [])
            .map((m) => {
                return { tag: m.tag, value: m.value, card: m.id }
            })
        if (state.customDisplay === 'popup' && !isDefined(state.timelineId)) {
            if (isDefined(state.updateAnswer)) {
                Medical.sendDoctorMedicalUpdateAnswer(
                    localStorage.getItem('idPatient'),
                    state.updateAnswerId,
                    medicalDatas,
                    false
                ).then((res) => {
                    setTimeout(() => {
                        actions.redirectToTimeline(true)
                    }, 1000)
                    return false
                })
            } else {
                Medical.sendDoctorMedicalAnswer(
                    localStorage.getItem('idPatient'),
                    state.medicalStudysurvey,
                    medicalDatas,
                    false
                ).then((res) => {
                    setTimeout(() => {
                        actions.redirectToTimeline(true)
                    }, 1000)
                    return false
                })
            }
        } else {
            if (state.customDisplay !== 'popup') {
                actions.setOnboardingEnd(true)
            }
            medicalDatas.timeline = state.current.idtimeline
            medicalDatas.userMedicalStudySurvey = state.medicalStudysurvey
            //
            medicalDatas.answers.map((data) => {
                const dataTag = data.tag.split('_')?.[0]?.toLowerCase()
                if (LocalesConversions?.[dataTag]) {
                    data.value = transformMetric(dataTag, data.value, 'output')
                }
            })
            //
            Medical.sendMedicalAnswer(medicalDatas, false).then((res) => {
                if (
                    res.data !== undefined &&
                    res.data.message &&
                    res.data.message === 'ok'
                ) {
                    // eslint-disable-next-line no-console
                    // alert(
                    //     'Everything works correctly, this is the API answer : ' +
                    //         JSON.stringify(res)
                    // )
                    //
                    if (isDefined(state.user)) {
                        localStorage.setItem(
                            'onboarding-viewed-' +
                                state.user?.id +
                                '-' +
                                getTodayYMD(),
                            true
                        )
                    }
                    setTimeout(() => {
                        // eslint-disable-next-line no-console
                        // alert('Heal consent should be triggred')
                        actions.triggerHealConsent()
                    }, 3000)
                    return false
                } else {
                    actions.setOnboardingEnd(false)
                    actions.setFormIsSubmitting(false)

                    // eslint-disable-next-line no-console
                    // alert(
                    //     'We are in an unwanted case, this is the API answer : ' +
                    //         JSON.stringify(res)
                    // )
                }
            })
        }
    },
    sendMedicalCardline:
        ({ idtimeline, thenClear, thenPost }) =>
        (state, actions) => {
            actions.setFormIsSubmitting(true)
            let lastPushedValues = state.values[state.values.length - 1]
            let parsedValues = {}
            parsedValues.answers = []
            if (Array.isArray(lastPushedValues)) {
                parsedValues.answers = lastPushedValues
            } else {
                parsedValues.answers.push(lastPushedValues)
            }
            parsedValues.answers = parsedValues.answers.map((m) => {
                const dataTag = m.tag.split('_')?.[0]?.toLowerCase()
                return {
                    tag: m.tag,
                    value: LocalesConversions?.[dataTag]
                        ? transformMetric(dataTag, m.value, 'output', null)
                        : m.value,
                    card: m.id,
                }
            })
            //
            if (state.customDisplay === 'popup' && !isDefined(idtimeline)) {
                Cardline.postDoctorMedicalCardline(
                    localStorage.getItem('idPatient'),
                    state.medicalStudysurvey,
                    parsedValues,
                    false
                ).then((res) => {
                    actions.sendMedicalCardlineCallback({
                        optionalChildrenCards: res.data,
                        lastPushedValues: lastPushedValues,
                        thenClear: thenClear,
                        thenPost: thenPost,
                    })
                })
            } else {
                Cardline.postMedicalCardline(
                    idtimeline,
                    parsedValues,
                    false
                ).then((res) => {
                    if (res.data === null) {
                        //
                        actions.removeConfirmQuitEvent()
                        actions.setOnboardingEnd(true)
                        return false
                    }
                    //
                    actions.sendMedicalCardlineCallback({
                        optionalChildrenCards: res.data,
                        lastPushedValues: lastPushedValues,
                        thenClear: thenClear,
                        thenPost: thenPost,
                    })
                })
            }
        },
    sendMedicalCardlineCallback:
        ({ optionalChildrenCards, lastPushedValues, thenClear, thenPost }) =>
        (state, actions) => {
            actions.setFormIsSubmitting(false)
            //
            // if we found child cards, we insert them at current index
            if (
                isDefined(optionalChildrenCards) &&
                optionalChildrenCards.length > 0
            ) {
                let childParsedData = actions.parseAndPushCards(
                    optionalChildrenCards
                )
                let childCards = childParsedData.stateSteps
                let numCardPrefix = state.current.numCard
                for (let childCard in childCards) {
                    childCards[childCard].numCard =
                        numCardPrefix +
                        '.' +
                        (isDefined(state.alpha[+childCard])
                            ? state.alpha[+childCard]
                            : +childCard + 1)
                }
                if (isDefined(childCards) && childCards.length > 0) {
                    let existingCards = state.steps
                    const insert = (arr, index, ...newItems) => [
                        ...arr.slice(0, index),
                        ...newItems.map((m) => {
                            m.parentId = lastPushedValues.id
                            return m
                        }),
                        ...arr.slice(index),
                    ]
                    let newCardsList = insert(
                        existingCards,
                        state.idx + 1,
                        ...childCards
                    )
                    actions.setSteps(newCardsList)
                }
            } else if (isDefined(thenPost)) {
                // for last medical card only,
                //  we must post it to verify if there is a child
                //   then, if there is no child, post the Cardline
                thenPost()
                return false
            }
            //
            actions.setUniformButtonHeight(0)
            actions.setIdx(state.idx + 1)
            actions.switchValidationActivation(false)
            actions.updateCurrentStep()
            //
            if (isDefined(thenClear)) {
                thenClear()
            }
        },
    selectThisAnswer: (el) => (state, actions) => {
        actions.setError('')
        if (el.target.getAttribute('data-kind') === 'qcm') {
            if (el.target.classList.contains('active')) {
                el.target.classList.remove('active')
                el.target.classList.remove('btzCurrentEvaluationMarker')
            } else {
                el.target.classList.add('active')
                el.target.classList.add('btzCurrentEvaluationMarker')
            }
        } else {
            var els = getClosest(
                el.target,
                '.btzOnboarding-btzButtonsWrapper'
            ).querySelectorAll('.btzOnBoarding-btzButton')
            for (var elem in els) {
                if (els[elem] && els[elem].classList !== undefined) {
                    els[elem].classList.remove('active')
                    els[elem].classList.remove('btzCurrentEvaluationMarker')
                }
            }
            if (!el.target.classList.contains('btzOnBoarding-btzButton')) {
                el = getClosest(el.target, '.btzOnBoarding-btzButton')
                el.classList.add('active')
                el.classList.add('btzCurrentEvaluationMarker')
            } else {
                el.target.classList.add('active')
                el.target.classList.add('btzCurrentEvaluationMarker')
            }
        }

        if (state.mobileOnboarding) {
            let targetEl = document.querySelector('div#autoScrollMarker')
            if (targetEl) {
                scrollTo(targetEl, targetEl.offsetHeight, 160)
            }
        }

        if (!state.activeValidation) {
            actions.switchValidationActivation(true)
        }
    },
    pushValues: (els) => (state, actions) => {
        let everythingOk = true
        els.forEach(function (el) {
            if (
                !isDefined(el.getAttribute('data-id')) &&
                isDefined(el.lastChild)
            ) {
                el = el.lastChild
            }
            var tag = el.getAttribute('data-tag'),
                val = el.getAttribute('data-value'),
                min = el.getAttribute('data-min'),
                max = el.getAttribute('data-max')
            //
            if (!isDefined(val) && isDefined(el.value)) {
                val = el.value
            }
            if (!isNaN(val.replace(',', '.'))) {
                val = val.replace(',', '.')
            }
            if (!isDefined(val) || val === '') {
                everythingOk = false
                actions.setError(
                    t('Veuillez renseigner une réponse.', { ns: 'onboarding' })
                )
            } else {
                if (tag == '-1' && val == '-1') {
                    // nothing, empty answer
                } else {
                    let numericValuesToTest = false
                    if (!isDefined(min) && !isDefined(max)) {
                        if (
                            tag === 'PERSONAL_WEIGHT' ||
                            tag === 'CRYPTEDWEIGHT_VALUE'
                        ) {
                            numericValuesToTest = true
                            let userMetrics = state.user?.metrics
                            let isValid =
                                parseFloat(val) >=
                                    convertMetrics(
                                        'metric',
                                        'weight',
                                        userMetrics,
                                        constants.custo.METRICS.weight.min
                                    ) &&
                                parseFloat(val) <=
                                    convertMetrics(
                                        'metric',
                                        'weight',
                                        userMetrics,
                                        constants.custo.METRICS.weight.max
                                    )
                            if (!isValid || val === '' || val === null) {
                                actions.setError(
                                    t(
                                        'Veuillez renseigner un poids compris entre',
                                        { ns: 'onboarding' }
                                    ) +
                                        ' ' +
                                        convertMetrics(
                                            'metric',
                                            'weight',
                                            userMetrics,
                                            constants.custo.METRICS.weight.min
                                        ) +
                                        convertMetricsLabels(
                                            true,
                                            'weight',
                                            userMetrics,
                                            'kg'
                                        ) +
                                        ' ' +
                                        t('et', { ns: 'generals' }) +
                                        ' ' +
                                        convertMetrics(
                                            'metric',
                                            'weight',
                                            userMetrics,
                                            constants.custo.METRICS.weight.max
                                        ) +
                                        convertMetricsLabels(
                                            true,
                                            'weight',
                                            userMetrics,
                                            'kg'
                                        )
                                )
                                everythingOk = false
                            }
                        } else if (tag === 'PERSONAL_HEIGHT') {
                            numericValuesToTest = true
                            let userMetrics =
                                state.user?.heightMetrics || state.user?.metrics
                            let isValid =
                                parseFloat(val) >=
                                    convertMetrics(
                                        'metric',
                                        'height',
                                        userMetrics,
                                        constants.custo.METRICS.height.min
                                    ) &&
                                parseFloat(val) <=
                                    convertMetrics(
                                        'metric',
                                        'height',
                                        userMetrics,
                                        constants.custo.METRICS.height.max
                                    )
                            if (!isValid || val === '' || val === null) {
                                actions.setError(
                                    t(
                                        'Veuillez renseigner une taille comprise entre',
                                        { ns: 'onboarding' }
                                    ) +
                                        ' ' +
                                        convertMetrics(
                                            'metric',
                                            'height',
                                            userMetrics,
                                            constants.custo.METRICS.height.min
                                        ) +
                                        convertMetricsLabels(
                                            true,
                                            'height',
                                            userMetrics,
                                            'cm'
                                        ) +
                                        ' ' +
                                        t('et', { ns: 'generals' }) +
                                        ' ' +
                                        convertMetrics(
                                            'metric',
                                            'height',
                                            userMetrics,
                                            constants.custo.METRICS.height.max
                                        ) +
                                        convertMetricsLabels(
                                            true,
                                            'height',
                                            userMetrics,
                                            'cm'
                                        )
                                )
                                everythingOk = false
                            }
                        } else if (
                            tag === 'PERSONAL_ABDOMINALPERIMETER' ||
                            tag === 'PERSONAL_WAISTSIZE'
                        ) {
                            numericValuesToTest = true
                            let userMetrics =
                                state.user?.heightMetrics || state.user?.metrics
                            let isValid =
                                parseFloat(val) >=
                                    convertMetrics(
                                        'metric',
                                        'abdo',
                                        userMetrics,
                                        constants.custo.METRICS.abdo.min
                                    ) &&
                                parseFloat(val) <=
                                    convertMetrics(
                                        'metric',
                                        'abdo',
                                        userMetrics,
                                        constants.custo.METRICS.abdo.max
                                    )
                            if (!isValid || val === '' || val === null) {
                                actions.setError(
                                    t(
                                        'Veuillez renseigner un tour de taille compris entre',
                                        { ns: 'onboarding' }
                                    ) +
                                        ' ' +
                                        convertMetrics(
                                            'metric',
                                            'abdo',
                                            userMetrics,
                                            constants.custo.METRICS.abdo.min
                                        ) +
                                        convertMetricsLabels(
                                            true,
                                            'abdo',
                                            userMetrics,
                                            'cm'
                                        ) +
                                        ' ' +
                                        t('et', { ns: 'generals' }) +
                                        ' ' +
                                        convertMetrics(
                                            'metric',
                                            'abdo',
                                            userMetrics,
                                            constants.custo.METRICS.abdo.max
                                        ) +
                                        convertMetricsLabels(
                                            true,
                                            'abdo',
                                            userMetrics,
                                            'cm'
                                        )
                                )
                                everythingOk = false
                            }
                        }
                    }
                    //
                    if (isDefined(min) && isDefined(max)) {
                        numericValuesToTest = true
                    }
                    //
                    let valuePrecision = ''
                    if (state.current.form.type === 'bloodpressure') {
                        if (tag === 'BLOODPRESSURE_SYSTOLIC') {
                            valuePrecision = t('pression systolique', {
                                ns: 'onboarding',
                            })
                        } else if (tag === 'BLOODPRESSURE_DIASTOLIC') {
                            valuePrecision = t('pression diastolique', {
                                ns: 'onboarding',
                            })
                        }
                    }
                    if (
                        parseFloat(val) < parseFloat(min) ||
                        parseFloat(val) > parseFloat(max)
                    ) {
                        if (isDefined(min) && isDefined(max)) {
                            if (valuePrecision !== '') {
                                actions.setError(
                                    t('Veuillez renseigner une valeur', {
                                        ns: 'onboarding',
                                    }) +
                                        ' (' +
                                        valuePrecision +
                                        ') ' +
                                        t('comprise entre', {
                                            ns: 'onboarding',
                                        }) +
                                        ' ' +
                                        min +
                                        ' ' +
                                        t('et', { ns: 'generals' }) +
                                        ' ' +
                                        max +
                                        ' ' +
                                        getUnitFromType(
                                            state.current.form?.type?.toLowerCase()
                                        )
                                )
                            } else {
                                actions.setError(
                                    t('Veuillez renseigner une valeur', {
                                        ns: 'onboarding',
                                    }) +
                                        ' ' +
                                        t('comprise entre', {
                                            ns: 'onboarding',
                                        }) +
                                        ' ' +
                                        min +
                                        ' ' +
                                        t('et', { ns: 'generals' }) +
                                        ' ' +
                                        max +
                                        ' ' +
                                        getUnitFromType(
                                            state.current.form?.type?.toLowerCase()
                                        )
                                )
                            }
                            everythingOk = false
                        } else {
                            if (isDefined(min)) {
                                if (valuePrecision !== '') {
                                    actions.setError(
                                        t('Veuillez renseigner une valeur', {
                                            ns: 'onboarding',
                                        }) +
                                            ' (' +
                                            valuePrecision +
                                            ') ' +
                                            t('supérieure à', {
                                                ns: 'onboarding',
                                            }) +
                                            ' ' +
                                            min +
                                            ' ' +
                                            getUnitFromType(
                                                state.current.form?.type?.toLowerCase()
                                            )
                                    )
                                } else {
                                    actions.setError(
                                        t('Veuillez renseigner une valeur', {
                                            ns: 'onboarding',
                                        }) +
                                            ' ' +
                                            t('supérieure à', {
                                                ns: 'onboarding',
                                            }) +
                                            ' ' +
                                            min +
                                            ' ' +
                                            getUnitFromType(
                                                state.current.form?.type?.toLowerCase()
                                            )
                                    )
                                }
                                everythingOk = false
                            } else if (isDefined(max)) {
                                if (valuePrecision !== '') {
                                    actions.setError(
                                        t('Veuillez renseigner une valeur', {
                                            ns: 'onboarding',
                                        }) +
                                            ' (' +
                                            valuePrecision +
                                            ') ' +
                                            t('inférieure à', {
                                                ns: 'onboarding',
                                            }) +
                                            ' ' +
                                            max +
                                            ' ' +
                                            getUnitFromType(
                                                state.current.form?.type?.toLowerCase()
                                            )
                                    )
                                } else {
                                    actions.setError(
                                        t('Veuillez renseigner une valeur', {
                                            ns: 'onboarding',
                                        }) +
                                            ' ' +
                                            t('inférieure à', {
                                                ns: 'onboarding',
                                            }) +
                                            ' ' +
                                            max +
                                            ' ' +
                                            getUnitFromType(
                                                state.current.form?.type?.toLowerCase()
                                            )
                                    )
                                }
                                everythingOk = false
                            }
                        }
                    }
                    //
                    if (numericValuesToTest && isDefined(val)) {
                        // ^\d+([.]\d)?$
                        let isValidNumber = val.match(/^\d+([,|.]\d+)?$/)
                        if (isValidNumber === null) {
                            everythingOk = false
                            actions.setError(
                                t('Veuillez renseigner une valeur valide.', {
                                    ns: 'onboarding',
                                })
                            )
                        }
                    }
                }
            }
        })
        //
        if (everythingOk) {
            if (state.current.form && state.current.form.type === 'qcm') {
                // push all (others) key to false, mandatory for qcm
                actions.pushDefaultValues(state.current.original)
            }
            let dualValues = []
            els.forEach(function (el) {
                if (
                    !isDefined(el.getAttribute('data-id')) &&
                    isDefined(el.lastChild)
                ) {
                    el = el.lastChild
                }
                var id = el.getAttribute('data-id'),
                    tag = el.getAttribute('data-tag'),
                    val = el.getAttribute('data-value')
                if (!isDefined(val) && isDefined(el.value)) {
                    val = el.value
                }
                if (tag == '-1' && val == '-1') {
                    // nothing, empty answer
                } else {
                    val = String(val).replace(',', '.')
                    if (state.current.form.type === 'qcm') {
                        // find value by tag then replace it
                        for (
                            var i = 0, len = state.values.length;
                            i < len;
                            i++
                        ) {
                            if (state.values[i] !== undefined) {
                                if (state.values[i].tag === tag) {
                                    state.values[i] = {
                                        tag: tag,
                                        value: val,
                                        id: id,
                                    }
                                }
                            }
                        }
                    } else {
                        if (state.current.form.type === 'bloodpressure') {
                            dualValues.push({
                                tag: tag,
                                value: parseFloat(val),
                                id: id,
                            })
                        } else {
                            state.values.push({ tag: tag, value: val, id: id })
                        }
                    }
                }
            })
            if (dualValues.length > 0) {
                state.values.push(dualValues)
            }
        }
        return everythingOk
    },
    // https://app-release.resilience.care/medical-study/144867ea091989163217038bd93f2481bf5696ad8ff08dad/61f29cbbc54a9749ca2d763e/61f29cbbc54a9749ca2d763f/19961119/?display=popup
    redirectToTimeline:
        (alreadyAnswered = false) =>
        (state, actions) => {
            localStorage.removeItem('medicalStudysurvey')
            localStorage.removeItem('medicalStudytoken')
            if (state.customDisplay === 'popup') {
                // eslint-disable-next-line no-console
                // alert(
                //     'redirectToTimeline > event should be dispatched to mobile'
                // )
                var eventFired = false
                try {
                    // eslint-disable-next-line
                    if (
                        isDefined(AndroidWebSurveyJsInterface) &&
                        isDefined(AndroidWebSurveyJsInterface.onSurveyFinished)
                    ) {
                        // eslint-disable-next-line
                        AndroidWebSurveyJsInterface.onSurveyFinished()
                        eventFired = true
                    } else {
                        throw new Error(
                            '[AndroidWebSurveyJsInterface] is not defined'
                        )
                    }
                } catch (error) {
                    // eslint-disable-next-line no-console
                    // alert('Cant dispatch event to mobile > ', error)
                }
                //
                if (!eventFired) {
                    try {
                        if (
                            window.ReactNativeWebView &&
                            window.ReactNativeWebView.postMessage
                        ) {
                            window.ReactNativeWebView.postMessage(
                                JSON.stringify({ finished: true })
                            )
                            eventFired = true
                        } else {
                            throw new Error(
                                '[window.ReactNativeWebView] is not defined'
                            )
                        }
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        // alert('Cant dispatch event to mobile > ', error)
                    }
                }
                //
                if (!eventFired) {
                    try {
                        if (window.webkit && window.webkit.messageHandlers) {
                            window.webkit.messageHandlers.defaultMessageHandler.postMessage(
                                JSON.stringify({ finished: true })
                            )
                            eventFired = true
                        } else {
                            throw new Error('[window.webkit] is not defined')
                        }
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        // alert('Cant dispatch event to mobile > ', error)
                    }
                }
                //
                if (!eventFired) {
                    if (!isDefined(state.timelineId)) {
                        // eslint-disable-next-line
                        var messager = new Messager({
                            remoteWindow: window.parent,
                            local: process.env.APP_WEB_URL,
                            remote: process.env.APP_WEBAPP_URL,
                        })
                        messager.send('updateTimelines', { param: null })
                    }
                }
                //
                return false
            }
            if (state.mobileOnboarding) {
                actions.removeConfirmQuitEvent()
            }
            window.location.href = addCSRFToken(
                allRoutes['private.timeline'].pathname +
                    '?justanswered=' +
                    state.timelineId
            )
        },
    redirectToLogin:
        (force = false) =>
        (state, actions) => {
            if (state.mobileOnboarding) {
                actions.removeConfirmQuitEvent()
            }
            window.location.href = addCSRFToken(
                allRoutes['home.login'].pathname
            )
        },
    updatePregnancyKind: (newState) => (state) => ({
        showPregnancyInputsStep: newState,
    }),
    pushDefaultValues: (datas) => (state, actions) => {
        for (var dat in datas.forms) {
            state.values.push({
                tag: datas.forms[dat].tag,
                value: false,
                id: datas.id,
            })
        }
    },
    setValues: (newState) => (state) => ({
        values: { ...state.values, ...newState },
    }),
    updateButtonHeight: (el) => (state, actions) => {
        let newButtonHeight = el.clientHeight
        if (+newButtonHeight > state.uniformButtonHeight) {
            actions.setUniformButtonHeight(newButtonHeight)
        }
    },
    setActiveEndSubmit: (newState) => (state) => ({
        activeEndSubmit: newState,
    }),
    setUniformButtonHeight: (newState) => (state) => ({
        uniformButtonHeight: newState,
    }),
    switchValidationActivation: (bool) => (state) => ({
        activeValidation: bool,
    }),
    updateCurrentStep: () => (state) => ({
        activeValidation: isDefined(state.steps[state.idx])
            ? [
                  'number',
                  'counter',
                  'statcounter',
                  'qcm',
                  'cryptedweight',
                  'cryptedtemperature',
                  'bloodpressure',
                  'heartbeat',
              ].indexOf(state.steps[state.idx].form.type) > -1
                ? true
                : false
            : false,
        current: state.steps[state.idx],
        defaultValue: state.updateAnswerExistingValues?.filter(
            (eVal) => eVal.evaluationName === state.steps[state.idx].name
        )?.[0]?.value,
    }),
    setFormIsSubmitting: (active) => (state, actions) => ({
        formIsSubmitting: active,
    }),
    setLastAnswerCallback: (newState) => (state) => ({
        lastanswercallback: newState,
    }),
    setSteps: (newState) => (state) => ({
        steps: newState,
    }),
    setIdx: (newState) => (state) => ({
        idx: newState,
    }),
    setSpecialInitialCount: (newState) => (state) => ({
        specialInitialCount: newState,
    }),
    setOnboardingEnd: (newState) => (state) => ({
        onboardingEnd: newState,
    }),
    displayEndBoard: (newState) => (state) => ({
        endBoard: newState,
    }),
    setLocale: (newState) => (state) => ({
        locale: newState,
    }),
    setLoaded: (newState) => (state) => ({
        loaded: newState,
    }),
    setCustomDisplay: (newState) => (state) => ({
        customDisplay: newState,
    }),
    setUpdateAnswer: (newState) => (state) => ({
        updateAnswer: newState,
    }),
    setUpdateAnswerId: (newState) => (state) => ({
        updateAnswerId: newState,
    }),
    setMedicalStudysurvey: (newState) => (state) => ({
        medicalStudysurvey: newState,
    }),
    setUpdateAnswerExistingValues: (newState) => (state) => ({
        updateAnswerExistingValues: newState,
    }),
    getTheme: (intTheme) => {
        for (var _t in constants.custo.THEMES) {
            if (constants.custo.THEMES[_t].key === intTheme) {
                return constants.custo.THEMES[_t].value
            }
        }
    },
    //
    triggerHealConsent: (alreadyAnswered) => (state, actions) => {
        let userCallback = (patient) => {
            if (
                !isDefined(patient.edsConsent) &&
                (state.customer === 'resilience' ||
                    state.customer === 'oncoflow')
            ) {
                // eslint-disable-next-line no-console
                // alert('Heal consent triggered')
                actions.setHealConsentDisplayed(true)
            } else {
                // eslint-disable-next-line no-console
                // alert('Redirected to timeline')
                actions.redirectToTimeline(true)
            }
        }

        getUser(userCallback)
    },
    onAcceptHealConsent: (accept) => (state, actions) => {
        if (accept) {
            Consent.postHealConsent(true).then((res) => {
                actions.closeHealConsent()
                actions.redirectToTimeline(true)
            })
        } else if (!accept) {
            Consent.postHealConsent(false).then((res) => {
                actions.closeHealConsent()
                actions.redirectToTimeline(true)
            })
        }
    },
    closeHealConsent: () => (state, actions) => {
        actions.setHealConsentDisplayed(false)
    },
    setHealConsentDisplayed: (newState) => (state, actions) => ({
        healConsentDisplayed: newState,
    }),
}

const view = dynLoad('view', 'Onboarding/views')

export default Component(state, actions, view, 'onboarding')
