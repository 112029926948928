import { t } from 'i18next'
import { h } from 'hyperapp'
import {
    Card,
    Button,
    Form,
    FormInput,
    Tile,
    Enter,
    Calendar,
    Modal,
    DateInput,
} from '@app/elements'
import * as constants from '@app/constants'
import { BotFooterView } from '@app/layouts/botfooter'

import ViewMonitoringConsent from './../monitoring-consent/view-monitoring-consent-default'

import { ModalViewResetPWD } from './../../modals/modalView-resetPwd-default'

import icoTopMobile from '@app/img/ico/mobile/ico-login-mobile.png'
import icoTopMobilex2 from '@app/img/ico/mobile/ico-login-mobile@2x.png'

const ViewSimpleAccess = (props, actions) => (
    <Enter
        time={constants.custo.ANIM_DURATION}
        easing={constants.custo.ANIM_EASING}
        css={constants.custo.ANIM_CSS}
    >
        {props.loginStep === 'welcome' && (
            <dummy>
                <div
                    class={
                        'thfProgressBarWrapper' +
                        (['resilience'].indexOf(process.env.APP_CUSTOMISATION) >
                        -1
                            ? ''
                            : ' hide-xs')
                    }
                >
                    <div class="thfProgressBar" style="width: 30%"></div>
                </div>
                {['resilience'].indexOf(process.env.APP_CUSTOMISATION) ===
                -1 ? (
                    <img
                        class="show-xs"
                        src={icoTopMobile}
                        srcset={`${icoTopMobilex2} 2x`}
                        alt=""
                    />
                ) : (
                    <dummy>
                        <img
                            class={
                                (process.env.APP_CUSTOMISATION.toLowerCase() ===
                                'resilience'
                                    ? 'show-xs'
                                    : '') + ' thfCustomerLogo'
                            }
                            style="max-width: 140px;"
                            src={`${
                                process.env.APP_WEB_URL
                            }/__logo/${props.customer.toLowerCase()}/logo-blue.png`}
                            srcset={`${
                                process.env.APP_WEB_URL
                            }/__logo/${props.customer.toLowerCase()}/logo-blue@2x.png`}
                            alt=""
                        />
                        {process.env.APP_CUSTOMISATION.toLowerCase() ===
                            'resilience' && (
                            <img
                                class="hide-xs thfCustomerLogo"
                                style="max-width: 140px;"
                                src={`${
                                    process.env.APP_WEB_URL
                                }/__logo/${props.customer.toLowerCase()}/logo-white.png`}
                                srcset={`${
                                    process.env.APP_WEB_URL
                                }/__logo/${props.customer.toLowerCase()}/logo-white@2x.png`}
                                alt=""
                            />
                        )}
                    </dummy>
                )}
                <Tile>
                    <Tile.Content>
                        <div class="loginHeader">
                            <p class="btzTitle btzXl">
                                {t('Bienvenue.', { ns: 'login' })}
                            </p>
                            <p class="btzSubTitle">
                                {t('Merci pour votre participation à l’étude', {
                                    ns: 'login',
                                }) +
                                    '. ' +
                                    t(
                                        'Pour compléter les questionnaires de l’étude, régulièrement, vous recevrez des notifications par SMS ou Email. Tous seront disponibles au bon moment dans votre espace Patient.',
                                        { ns: 'login' }
                                    )}
                            </p>
                        </div>
                    </Tile.Content>
                </Tile>

                <Form
                    onsubmit={(event) => event.preventDefault()}
                    autocomplete={'on'}
                >
                    <Form.Group classes="btzForm-btzFooter">
                        <Button
                            primary
                            loading={props.formIsSubmitting}
                            active
                            onclick={() => {
                                props._setState({
                                    key: 'loginStep',
                                    value: 'login',
                                })
                            }}
                        >
                            {t('Continuer', { ns: 'login' })}
                        </Button>
                    </Form.Group>
                </Form>
            </dummy>
        )}
        {props.loginStep === 'login' && (
            <dummy>
                <div class="thfProgressBarWrapper hide-xs">
                    <div class="thfProgressBar" style="width: 70%"></div>
                </div>
                {['resilience'].indexOf(process.env.APP_CUSTOMISATION) ===
                -1 ? (
                    <img
                        class="show-xs"
                        src={icoTopMobile}
                        srcset={`${icoTopMobilex2} 2x`}
                        alt=""
                    />
                ) : (
                    <dummy>
                        <img
                            class={
                                (process.env.APP_CUSTOMISATION.toLowerCase() ===
                                'resilience'
                                    ? 'show-xs'
                                    : '') + ' thfCustomerLogo'
                            }
                            style="max-width: 140px;"
                            src={`${
                                process.env.APP_WEB_URL
                            }/__logo/${props.customer.toLowerCase()}/logo-blue.png`}
                            srcset={`${
                                process.env.APP_WEB_URL
                            }/__logo/${props.customer.toLowerCase()}/logo-blue@2x.png`}
                            alt=""
                        />
                        {process.env.APP_CUSTOMISATION.toLowerCase() ===
                            'resilience' && (
                            <img
                                class="hide-xs thfCustomerLogo"
                                style="max-width: 140px;"
                                src={`${
                                    process.env.APP_WEB_URL
                                }/__logo/${props.customer.toLowerCase()}/logo-white.png`}
                                srcset={`${
                                    process.env.APP_WEB_URL
                                }/__logo/${props.customer.toLowerCase()}/logo-white@2x.png`}
                                alt=""
                            />
                        )}
                    </dummy>
                )}
                {props.askForMonitoringConsent ? (
                    <ViewMonitoringConsent
                        redirectUserToGoodIndex={props.redirectUserToGoodIndex}
                        refuseMonitoring={props.refuseMonitoring}
                        refusedMonitoring={props.refusedMonitoring}
                    />
                ) : (
                    <div>
                        <Tile>
                            <Tile.Content>
                                <div class="loginHeader">
                                    <p class="btzTitle btzXl">
                                        {t('Identifiez-vous.', { ns: 'login' })}
                                    </p>
                                    <p class="btzSubTitle">
                                        {t(
                                            'Pour répondre à votre questionnaire, renseignez votre date de naissance.',
                                            { ns: 'login' }
                                        )}
                                    </p>
                                </div>
                            </Tile.Content>
                        </Tile>
                        <Form
                            onsubmit={(event) => event.preventDefault()}
                            autocomplete={'on'}
                        >
                            <button
                                type="submit"
                                disabled
                                style="display: none"
                                aria-hidden="true"
                            ></button>
                            <Form.Group>
                                <p class="btzForm-btzLabel">
                                    {t('Votre date de naissance', {
                                        ns: 'login',
                                    })}
                                </p>
                                <DateInput
                                    key="input-birthdate"
                                    id="birthdate"
                                    name="birthdate"
                                    value={props.values.birthdate}
                                    validation={true}
                                    oninputcb={props._retrieveInput}
                                    onblurcb={props.retrieveErrorMessage}
                                    formSubmittedOnce={props.formSubmittedOnce}
                                    errorMessage={props.errorMessages.birthdate}
                                    isTyping={props.isTyping.birthdate}
                                    isValid={
                                        props.formSubmittedOnce &&
                                        !props.isTyping.birthdate
                                            ? props.isValid.birthdate
                                            : 'is-default'
                                    }
                                    required
                                />
                            </Form.Group>
                            {props.errorMessages.global !== '' && (
                                <p
                                    class="btzForm-btzFormInput-errorMessage btzForm-btzFormInput-errorMessageForced"
                                    style="height: 0px;margin: -5px 0 0;"
                                >
                                    {props.errorMessages.global}
                                </p>
                            )}
                            <Form.Group classes="btzForm-btzFooter">
                                <Button
                                    primary
                                    loading={props.formIsSubmitting}
                                    active={props.isValid.birthdate}
                                    onclick={props.onSubmit}
                                >
                                    {t('Me connecter', { ns: 'login' })}
                                </Button>
                            </Form.Group>
                        </Form>
                    </div>
                )}
            </dummy>
        )}
        <div class="show-xs">
            <BotFooterView
                customer={props.customer}
                medicalInfos={props.medicalInfos}
            ></BotFooterView>
        </div>
    </Enter>
)

export default ViewSimpleAccess
