import { isDefined, allRoutes } from '@app/core'
import { addCSRFToken } from './csrf'
import { Session } from '@app/api'

const clearCache = () => {
    let persistLocalStorage = []
    for (var i = 0, len = localStorage.length; i < len; ++i) {
        if (
            localStorage.key(i).includes('csrftoken') ||
            localStorage.key(i).includes('onboarding-viewed-') ||
            localStorage.key(i).includes('language') ||
            localStorage.key(i).includes('locale') ||
            localStorage.key(i).includes('displaybannerLastDisplay')
        ) {
            persistLocalStorage.push({
                key: localStorage.key(i),
                value: localStorage.getItem(localStorage.key(i)),
            })
        }
    }
    localStorage.clear()
    for (var j = 0, lenj = persistLocalStorage.length; j < lenj; ++j) {
        localStorage.setItem(
            persistLocalStorage[j].key,
            persistLocalStorage[j].value
        )
    }
}

const logoutAndRedirect = function (customer, mustBeRedirected = true) {
    Session.destroySession()
        .catch(() => {
            //if the auth token cannot be deleted on the API we do nothing
            //as we don't want to prevent the user for being disconnected
        })
        .finally(() => {
            if (!isDefined(customer)) {
                customer = process.env.APP_CUSTOMISATION
            }
            //
            clearCache()
            let redirect = allRoutes['index'].pathname
            if (mustBeRedirected) {
                setTimeout(() => {
                    window.location.href = addCSRFToken(redirect)
                }, 210)
            }
        })
}

const idleTime = 3600000 // 3600000 ms = 1 hour
let idleTimeout = null
const resetIdleLogoutTimer = function () {
    clearTimeout(idleTimeout)
    idleTimeout = setTimeout(() => {
        logoutAndRedirect()
    }, idleTime)
}

const idleLogout = function () {
    window.onload = resetIdleLogoutTimer
    window.onmousemove = resetIdleLogoutTimer
    window.onmousedown = resetIdleLogoutTimer // catches touchscreen presses as well
    window.ontouchstart = resetIdleLogoutTimer // catches touchscreen swipes as well
    window.onclick = resetIdleLogoutTimer // catches touchpad clicks as well
    window.onkeypress = resetIdleLogoutTimer
    window.addEventListener('scroll', resetIdleLogoutTimer, true)
}

export { logoutAndRedirect, idleLogout, clearCache }
