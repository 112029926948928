import { t } from 'i18next';
import { h } from 'hyperapp';
import { Card, Button, Form, FormInput, Tile, Enter, Calendar, Modal } from '@app/elements';
import * as constants from '@app/constants';
import { BotFooterView } from '@app/layouts/botfooter';

import { ModalViewResetPWD } from './../../modals/modalView-resetPwd-default';

import icoInfo from '@app/img/ico/ico-login-info.png';
import icoInfox2 from '@app/img/ico/ico-login-info@2x.png';

import icoTopMobile from '@app/img/ico/mobile/ico-login-mobile.png';
import icoTopMobilex2 from '@app/img/ico/mobile/ico-login-mobile@2x.png';

const ViewWelcome = (props, actions) => (
    <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
        <dummy>
            <div class='thfProgressBarWrapper hide-xs'><div class='thfProgressBar' style='width: 30%'></div></div>
            <img class={((process.env.APP_CUSTOMISATION.toLowerCase() === 'resilience' ? 'show-xs' : '' ) + ' thfCustomerLogo')} style='max-width: 140px;' src={`${process.env.APP_WEB_URL}/__logo/${props.customer.toLowerCase()}/logo-blue.png`} srcset={`${process.env.APP_WEB_URL}/__logo/${props.customer.toLowerCase()}/logo-blue@2x.png`} alt='' />
            {process.env.APP_CUSTOMISATION.toLowerCase() === 'resilience' &&
                <img class='hide-xs thfCustomerLogo' style='max-width: 140px;' src={`${process.env.APP_WEB_URL}/__logo/${props.customer.toLowerCase()}/logo-white.png`} srcset={`${process.env.APP_WEB_URL}/__logo/${props.customer.toLowerCase()}/logo-white@2x.png`} alt='' />
            }
            <Tile>
                <Tile.Content>
                    <div class='loginHeader'>
                        <p class='btzTitle btzXl'>{t('Bienvenue.', {ns: 'login'})}</p>
                        <p class='btzSubTitle'>{t('Ceci est votre espace. C\'est ici que vous pourrez répondre aux questionnaires de votre équipe soignante. À votre portée, une bibliothèque de contenus est également mise à votre disposition pour mieux comprendre ce que vous vivez, et agir en cas d\'apparition de symptômes.', {ns: 'login'})}</p>
                    </div>
                </Tile.Content>
            </Tile>

            <Form onsubmit={event => event.preventDefault()} autocomplete={'on'}>
                <button type='submit' disabled style='display: none' aria-hidden='true'></button>
                <Form.Group classes='btzForm-btzFooter'>
                    <Button primary active onclick={() => { props._setState({key: 'displayWelcome', value: false}); }}>
                        {t('Découvrir mon espace', {ns: 'login'})}
                    </Button>
                </Form.Group>
            </Form>
        </dummy>
        <div class='show-xs'><BotFooterView customer={props.customer}></BotFooterView></div>
    </Enter>
);

export default ViewWelcome;