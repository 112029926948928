/*
Settings that are responsible for application' configuration,
and can be overriden per item basis by using the corresponding
variable environment, e.g.:
`API_URL="https://domain.tld" yarn start`
*/

export { dynLoad } from './dyn-load'
export { coreFn } from './coreFn'
export { clientErrorsHandler } from './clientErrorsHandler'
//
export { $ } from './tools/$'
export { getClosest } from './tools/getClosest'
export { castToBool } from './tools/castToBool'
export { getDiffDate } from './tools/getDiffDate'
export { getMinHourFromMinutes } from './tools/getMinHourFromMinutes'
export { getQueryVariable } from './tools/getQueryVariable'
export { tryToLoginAndRedirect } from './tools/tryToLoginAndRedirect'
export { convertDateToUTC } from './tools/convertDateToUTC'
export { humanizeDate } from './tools/humanizeDate'
export { createCookie, readCookie, eraseCookie } from './tools/cookie'
export { scrollTo } from './tools/scrollTo'
export { isDefined } from './tools/isDefined'
export { guid } from './tools/guid'
export { createDateComplientSafari } from './tools/createDateComplientSafari'
export { uuidv4 } from './tools/uuidv4'
export { isEmail } from './tools/isEmail'
export { addZero } from './tools/addZero'
export { deepCopy } from './tools/deepCopy'
export { convertMetrics, convertMetricsLabels } from './tools/convertMetrics'
export { getUser, tryToGetUser, getSupport } from './tools/getUser'
export { setUser } from './tools/setUser'
export { parseToPercent } from './tools/parseToPercent'
export { capitalize } from './tools/capitalize'
export { compareByKey } from './tools/compareByKey'
export { trimSpecialChar } from './tools/trimSpecialChar'
export { isFloat, isInteger, castToNumberOfFloat } from './tools/number'
export { isPublicRoute } from './tools/isPublicRoute'
export { isMobile, getMobileOperatingSystem } from './tools/isMobile'
export { isMobileOrTablet } from './tools/isMobileOrTablet'
export { isTestMode } from './tools/isTestMode'
export { sortStringNumber } from './tools/sortStringNumber'
export {
    addCSRFToken,
    checkForValidCSRFToken,
    createAndSaveCSRFToken,
} from './tools/csrf'
export { getDateObjFromYMD } from './tools/getDateObjFromYMD'
export { getYMDfromDateObj } from './tools/getYMDfromDateObj'
export { getTodayYMD } from './tools/getTodayYMD'
export { logoutAndRedirect, idleLogout } from './tools/logoutAndRedirect'
export { createUTCDate } from './tools/createUTCDate'
export { convertObjectToArray } from './tools/convertObjectToArray'
export { displayErrors } from './tools/displayErrors'
export { isValidDate } from './tools/isValidDate'
export { getReadableDate } from './tools/getReadableDate'
export { ensurePasswordStrength } from './tools/ensurePasswordStrength'
export { getHash } from './tools/getHash'
export {
    parseJsonObjectToString,
    parseStringToJsonObj,
} from './tools/parseJsonObj'
export { markedDownContent, sanitizeContent } from './tools/markedDownContent'
export {
    autoSelectInput,
    autoClicAndSelectInput,
} from './tools/autoSelectInput'
//
export { allRoutes } from './routes'
export { settings } from './settings'
export { iePolyfills } from './iePolyfills'

export { redirectToOnboarding, redirectToGoodIndex } from './special_tools'
