//async
const dynLoad = function (
    type,
    baseurl,
    root = 'modules',
    condition = 'custo'
) {
    let _v = null
    if (baseurl !== '') {
        baseurl += '/'
    }
    if (root !== '' || root === 'modules') {
        root += '/'
    }
    try {
        let _custo = '',
            _targetCusto = '',
            _targetServer = ''
        if (condition.indexOf('custo') > -1) {
            _targetCusto = process.env.APP_CUSTOMISATION.toLowerCase()
            if (_targetCusto === 'theraflow') {
                _targetCusto = 'default'
            }
        }
        if (condition.indexOf('server') > -1) {
            _targetServer = process.env.APP_WEB_URL.toLowerCase()
            // _targetServer = 'https://theradmin-release.betterise.me/';
            if (_targetServer.match(/release/) !== null) {
                _targetServer = 'release'
            }
            if (_targetCusto !== '') {
                _targetServer = '-' + _targetServer
            }
        }
        _custo = _targetCusto + _targetServer
        _v = require('./../' + root + baseurl + type + '-' + _custo + '.js')
    } catch (error) {
        _v = require('./../' + root + baseurl + type + '-default.js')

        /*
        _v = require(`@app/${root}${baseurl}${type}-${_custo}.js`);
    } catch (error) {
        _v = require(`@app/${root}${baseurl}${type}-default.js`);
        */

        /*_v = await import(`${root}${baseurl}${type}-${_custo}.js`);
    } catch (error) {
        _v = await import(`${root}${baseurl}${type}-default.js`);*/
    }

    if (type === 'view') {
        if (_v.default !== undefined) {
            return _v.default.initView()
        }
        return _v.initView()
    } else if (type === 'action') {
        if (_v.default !== undefined) {
            return _v.default.initAction()
        }
        return _v.initAction()
    }
    return _v
}

export { dynLoad }
