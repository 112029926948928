import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { API_URL } = settings;

function getAlert(id) {
    return queueQueries(`${API_URL}/theraflow/configure-alerts/${id}/`, 'GET');
}
function createAlert(id, data) {
    return queueQueries(`${API_URL}/theraflow/configure-alerts/${id}/`, 'POST', 'application/json', true, true, true, true, true, data);
}
function deleteAlert(id) {
    return queueQueries(`${API_URL}/theraflow/configure-alerts/${id}/`, 'DELETE');
}
function getAlertEval(date) {
    return queueQueries(`${API_URL}/eval/responses/55/${date}/`, 'GET');
}
function getAlertMesure(id, alertid) {
    return queueQueries(`${API_URL}/theraflow/user-alerts/${id}/${alertid}/measure/`, 'GET');
}
function getUserAlertRecent(id, page, data) {
    return queueQueries(`${API_URL}/theraflow/user-alerts/${id}/recent/${page}/`, 'POST', 'application/json', true, true, true, true, true, data);
}
function getUserAlertAll(id, page, data) {
    return queueQueries(`${API_URL}/theraflow/user-alerts/${id}/page/${page}/`, 'POST', 'application/json', true, true, true, true, true, data);
}
function getUserAlertByDataType(id, type, date, period) {
    return queueQueries(`${API_URL}/theraflow/user-alerts/${id}/${type}/${date}/${period}/`, 'GET');
}
function getSurveyAlerts(page) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/alert/all/search/${page}/`, 'GET');
}
function getAlertAll(page, data) {
    return queueQueries(`${API_URL}/theraflow/user-alerts/all/search/${page}/`, 'POST', 'application/json', true, true, true, true, true, data);
}
function getSurveyPage(surveyalertid) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/alert/redirect/response/${surveyalertid}/`, 'GET');
}
function updateUserAlert(id, alertid, data) {
    return queueQueries(`${API_URL}/theraflow/user-alerts/${id}/${alertid}/`, 'PUT', 'application/json', true, true, true, true, true, data);
}

function getTheraflowUserById(id) {
    return queueQueries(`${API_URL}/theraflow/users/${id}/`, 'GET');
}
function blockTheraflowUser(id) {
    return queueQueries(`${API_URL}/theraflow/users/${id}/block/`, 'GET');
}
function searchTheraflowUser(data) {
    return queueQueries(`${API_URL}/theraflow/users/search/`, 'GET', true, true, true, true, true, data);
}

export const Alert = {
    getAlert,
    createAlert,
    deleteAlert,
    getAlertEval,
    getAlertMesure,
    getUserAlertRecent,
    getUserAlertAll,
    getUserAlertByDataType,
    getSurveyAlerts,
    getAlertAll,
    updateUserAlert,
    getSurveyPage,
};