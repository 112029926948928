import { h } from 'hyperapp'
import { t } from 'i18next'
import { dynLoad, isDefined } from '@app/core'
import SmartBanner from 'smart-app-banner'
import './index.scss'
import 'smart-app-banner/dist/smart-app-banner.css'

export const bannerInfo = dynLoad('info', 'Meta/custo/info', 'elements')
const bannerImg = bannerInfo.BANNER.IMG

let metaLastPath = ''
let metaSmartBannerTMT = null
const Meta = (props, children) => {
    let pageTitle = 'Betterise'
    let pageDescription = 'Betterise INC'
    if (metaLastPath != location.pathname) {
        if (document.querySelector('title') && props.title) {
            document.querySelector('title').text = props.title
            document
                .querySelector('meta[name=ogtitle]')
                .setAttribute('content', props.title)
            pageTitle = props.title
        }
        if (
            document.querySelector('meta[name=description]') &&
            props.description
        ) {
            document
                .querySelector('meta[name=description]')
                .setAttribute('content', props.description)
            document
                .querySelector('meta[name=ogdescription]')
                .setAttribute('content', props.description)
            pageDescription = props.description
        }
        if (document.querySelector('meta[name=image]') && props.image) {
            document
                .querySelector('meta[name=image]')
                .setAttribute('content', props.image)
            document
                .querySelector('meta[name=ogimage]')
                .setAttribute('content', props.image)
        }
        if (document.querySelector('meta[name=url]') && props.url) {
            document
                .querySelector('meta[name=url]')
                .setAttribute('content', props.url)
            document
                .querySelector('meta[name=ogurl]')
                .setAttribute('content', props.url)
        }
        if (document.querySelector('meta[name=type]') && props.type) {
            document
                .querySelector('meta[name=type]')
                .setAttribute('content', props.type)
            document
                .querySelector('meta[name=ogtype]')
                .setAttribute('content', props.type)
        }
        if (document.querySelector('link[name=favicon]') && props.favicon) {
            let milliseconds = new Date().getTime()
            document
                .querySelector('link[name=favicon]')
                .setAttribute('href', props.favicon + '?v=' + milliseconds)
        }

        /*
        apple-itunes-app && google-play-app
        these two meta are added with /public/js/appbanner/appb-[customer].js
        then SmartBanner enable ios Chrome banner (plus ios Safari one)
        the only way to get app banner working
         */

        if (isDefined(props.iosbanner) && isDefined(props.androidbanner)) {
            metaLastPath = location.pathname
            metaSmartBannerTMT = setTimeout(() => {
                clearTimeout(metaSmartBannerTMT)
                metaSmartBannerTMT = null
                new SmartBanner({
                    daysHidden: 1, // days to hide banner after close button is clicked (defaults to 15)
                    daysReminder: 7, // days to hide banner after "VIEW" button is clicked (defaults to 90)
                    // appStoreLanguage: 'fr', // language code for the App Store (defaults to user's browser language)
                    title: pageTitle,
                    author: pageDescription,
                    button: t('VOIR', { ns: 'generals' }),
                    store: {
                        ios: 'App Store',
                        android: 'Google Play',
                        windows: 'Windows Store',
                    },
                    price: {
                        ios: t('GRATUIT', { ns: 'generals' }),
                        android: t('GRATUIT', { ns: 'generals' }),
                        windows: t('GRATUIT', { ns: 'generals' }),
                    },
                    // force: 'android',
                    icon: bannerImg,
                    // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
                    // , icon: '' // full path to icon image if not using website icon image
                    // , force: 'ios' // Uncomment for platform emulation
                })
            }, 500)
        }
    }
}
export { Meta }
