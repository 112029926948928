import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { API_URL } = settings

function updateSettings(data) {
    return queueQueries(
        `${API_URL}/settings/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function getUser() {
    return queueQueries(`${API_URL}/user/`, 'GET')
}
function saveDoctor(data) {
    return queueQueries(
        `${API_URL}/doctors/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        false,
        true,
        data
    )
}
function updateDoctor(data) {
    return queueQueries(
        `${API_URL}/user/`,
        'PUT',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}
function askReset(data) {
    return queueQueries(
        `${API_URL}/doctors/session/ask-reset/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        false,
        true,
        data
    )
}
function checkReset(data) {
    return queueQueries(
        `${API_URL}/doctors/session/check-reset/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        false,
        true,
        data
    )
}
function doReset(data) {
    return queueQueries(
        `${API_URL}/doctors/session/do-reset/`,
        'POST',
        'application/json',
        true,
        true,
        true,
        false,
        true,
        data
    )
}
function getInfosFromToken(token) {
    return queueQueries(
        `${API_URL}/doctors/${token}/info`,
        'GET',
        'application/json',
        true,
        true,
        true,
        false,
        true,
        null
    )
}
function getInfo() {
    return queueQueries(`${API_URL}/theraflow/infos/`, 'GET')
}

export const Admin = {
    updateSettings,
    getUser,
    saveDoctor,
    updateDoctor,
    askReset,
    checkReset,
    doReset,
    getInfosFromToken,
    getInfo,
}
