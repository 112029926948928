import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { API_URL } = settings;
const ECOMPENDIUM_APIKEY = '14D32861-0CF4-4889-8B4B-2A1B09DFB20';//'5A316460-679F-4ABD-A8AA-ED7C51B8B9BA';

function getTreatments(id) {
    return queueQueries(`${API_URL}/theraflow/treatments/${id}/`, 'GET');
}
function getTreatment(id, treatid) {
    return queueQueries(`${API_URL}/theraflow/treatments/${id}/${treatid}/`, 'GET');
}
function addTreatment(id, data) {
    return queueQueries(`${API_URL}/theraflow/treatments/${id}/`, 'POST', 'application/json', true, true, true, true, true, data);
}
function updateTreatment(id, treatid, data) {
    return queueQueries(`${API_URL}/theraflow/treatments/${id}/${treatid}/`, 'PUT', 'application/json', true, true, true, true, true, data);
}
function searchTreatment(search, lang = 'FR', maxRow = 20) {
    return queueQueries(`${API_URL}/theraflow/treatments/medicine/${search}/`, 'GET');
}
// https://www.e-compendium.be/cfc/leaflets.cfc?method=getLeafletsRemote&amp;amp;returnformat=json&lang=FR&leaflet_type=SPC&product=as&maxRow=20&apikey=5A316460-679F-4ABD-A8AA-ED7C51B8B9BA

export const Treatment = {
    getTreatment,
    getTreatments,
    addTreatment,
    updateTreatment,
    searchTreatment
};