import { t } from 'i18next'
import { h } from 'hyperapp'
import {
    Card,
    Button,
    Form,
    FormInput,
    Tile,
    Enter,
    Calendar,
    Modal,
    DateInput,
} from '@app/elements'
import * as constants from '@app/constants'
import { BotFooterView } from '@app/layouts/botfooter'

import { ModalViewResetPWD } from './../../modals/modalView-resetPwd-default'

import ViewMonitoringConsent from './../monitoring-consent/view-monitoring-consent-default'

import icoInfo from '@app/img/ico/ico-login-info.png'
import icoInfox2 from '@app/img/ico/ico-login-info@2x.png'
import icoInfoGrey from '@app/img/ico/custo/resilience/ico-login-info.png'
import icoInfoGreyx2 from '@app/img/ico/custo/resilience/ico-login-info@2x.png'

import icoTopMobile from '@app/img/ico/mobile/ico-login-mobile.png'
import icoTopMobilex2 from '@app/img/ico/mobile/ico-login-mobile@2x.png'

const ViewDirectAccess = (props, actions) => (
    <Enter
        time={constants.custo.ANIM_DURATION}
        easing={constants.custo.ANIM_EASING}
        css={constants.custo.ANIM_CSS}
    >
        <dummy>
            <div
                class={
                    'thfProgressBarWrapper' +
                    (['resilience'].indexOf(process.env.APP_CUSTOMISATION) > -1
                        ? ''
                        : ' hide-xs')
                }
            >
                <div class="thfProgressBar" style="width: 70%"></div>
            </div>
            {['resilience'].indexOf(process.env.APP_CUSTOMISATION) === -1 ? (
                <img
                    class="show-xs"
                    src={icoTopMobile}
                    srcset={`${icoTopMobilex2} 2x`}
                    alt=""
                />
            ) : (
                <dummy>
                    <img
                        class={
                            (process.env.APP_CUSTOMISATION.toLowerCase() ===
                            'resilience'
                                ? 'show-xs'
                                : '') + ' thfCustomerLogo'
                        }
                        style="max-width: 140px;"
                        src={`${
                            process.env.APP_WEB_URL
                        }/__logo/${props.customer.toLowerCase()}/logo-blue.png`}
                        srcset={`${
                            process.env.APP_WEB_URL
                        }/__logo/${props.customer.toLowerCase()}/logo-blue@2x.png`}
                        alt=""
                    />
                    {process.env.APP_CUSTOMISATION.toLowerCase() ===
                        'resilience' && (
                        <img
                            class="hide-xs thfCustomerLogo"
                            style="max-width: 140px;"
                            src={`${
                                process.env.APP_WEB_URL
                            }/__logo/${props.customer.toLowerCase()}/logo-white.png`}
                            srcset={`${
                                process.env.APP_WEB_URL
                            }/__logo/${props.customer.toLowerCase()}/logo-white@2x.png`}
                            alt=""
                        />
                    )}
                </dummy>
            )}

            {props.askForMonitoringConsent ? (
                <ViewMonitoringConsent
                    redirectUserToGoodIndex={props.redirectUserToGoodIndex}
                    refuseMonitoring={props.refuseMonitoring}
                    refusedMonitoring={props.refusedMonitoring}
                />
            ) : (
                <div>
                    <Tile>
                        <Tile.Content>
                            <div class="loginHeader">
                                <p class="btzTitle btzXl">
                                    {t('Connectez-vous.', { ns: 'login' })}
                                </p>
                                <p class="btzSubTitle">
                                    {t(
                                        'Pour vous connecter, renseignez votre numéro de téléphone ou votre email, complété par votre date de naissance.',
                                        { ns: 'login' }
                                    )}
                                </p>
                            </div>
                        </Tile.Content>
                    </Tile>
                    <Form
                        onsubmit={(event) => event.preventDefault()}
                        autocomplete={'on'}
                    >
                        <button
                            type="submit"
                            disabled
                            style="display: none"
                            aria-hidden="true"
                        ></button>
                        <Form.Group>
                            <p class="btzForm-btzLabel">
                                {t('Votre numéro de téléphone ou Email', {
                                    ns: 'login',
                                })}
                            </p>
                            <FormInput
                                placeholder={[
                                    '+xx',
                                    'xx',
                                    'xx',
                                    'xx',
                                    'xx',
                                    'xx',
                                ]}
                                key="input-phoneOrEmail"
                                type="phoneOrEmail"
                                id="phoneOrEmail"
                                name="phoneOrEmail"
                                valueType="phoneOrEmail"
                                value={props.values.phoneOrEmail}
                                validation={true}
                                dynFocus={true}
                                isValid={
                                    props.formSubmittedOnce
                                        ? props.isValid.phoneOrEmail
                                        : 'is-default'
                                }
                                loaded={props.loaded}
                                oninputcb={props._retrieveInput}
                                onblurcb={props.retrieveErrorMessage}
                                formSubmittedOnce={props.formSubmittedOnce}
                                errorMessage={props.errorMessages.phoneOrEmail}
                                isTyping={props.isTyping.phoneOrEmail}
                                required
                            />
                            <Modal
                                key={'modal-doctor-email'}
                                customClass={'smallModal infoClass'}
                                cancelAction={() => {
                                    props._setState({
                                        key: 'modalDisplayed',
                                        value: false,
                                    })
                                }}
                                modalDisplayed={props.modalDisplayed}
                                content={
                                    <ModalViewResetPWD
                                        onSubmit={() => {
                                            props._setState({
                                                key: 'modalDisplayed',
                                                value: false,
                                            })
                                        }}
                                        cancelAction={() => {
                                            props._setState({
                                                key: 'modalDisplayed',
                                                value: false,
                                            })
                                        }}
                                        modalEditing={'resetPwd'}
                                        loaded={true}
                                    ></ModalViewResetPWD>
                                }
                            >
                                <div
                                    onclick={() => {
                                        props._setState({
                                            key: 'modalDisplayed',
                                            value: true,
                                        })
                                    }}
                                >
                                    {process.env.APP_CUSTOMISATION.toLowerCase() ===
                                    'resilience' ? (
                                        <img
                                            src={icoInfoGrey}
                                            srcset={`${icoInfoGreyx2} 2x`}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={icoInfo}
                                            srcset={`${icoInfox2} 2x`}
                                            alt=""
                                        />
                                    )}
                                </div>
                            </Modal>
                        </Form.Group>
                        <Form.Group>
                            <p class="btzForm-btzLabel">
                                {t('Votre date de naissance', { ns: 'login' })}
                            </p>
                            <DateInput
                                key="input-birthdate"
                                id="birthdate"
                                name="birthdate"
                                value={props.values.birthdate}
                                validation={true}
                                oninputcb={props._retrieveInput}
                                onblurcb={props.retrieveErrorMessage}
                                formSubmittedOnce={props.formSubmittedOnce}
                                errorMessage={props.errorMessages.birthdate}
                                isTyping={props.isTyping.birthdate}
                                isValid={
                                    props.formSubmittedOnce &&
                                    !props.isTyping.birthdate
                                        ? props.isValid.birthdate
                                        : 'is-default'
                                }
                                required
                            />
                        </Form.Group>
                        {props.errorMessages.global !== '' && (
                            <p
                                class="btzForm-btzFormInput-errorMessage btzForm-btzFormInput-errorMessageForced"
                                style="height: 24px; margin: -27px 0px 0px;"
                            >
                                {props.errorMessages.global}
                            </p>
                        )}
                        <Form.Group classes="btzForm-btzFooter">
                            <Button
                                primary
                                loading={props.formIsSubmitting}
                                active={props.isValid.birthdate}
                                onclick={props.onSubmit}
                            >
                                {t('Me connecter', { ns: 'login' })}
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            )}
        </dummy>
        <div class="show-xs">
            <BotFooterView customer={props.customer}></BotFooterView>
        </div>
    </Enter>
)

export default ViewDirectAccess
