import { t } from 'i18next'
import { Session, Cardline, User } from '@app/api'
import {
    getUser,
    redirectToOnboarding,
    allRoutes,
    addCSRFToken,
    isDefined,
    redirectToGoodIndex,
    coreFn,
    isEmail,
    getQueryVariable,
    scrollTo,
} from '@app/core'
import { getLocalStoredLocale } from '@app/core/languagesHandler'
import { sendLanguageToApi } from '@app/core/languagesSyncWithApi'
import { isIndexPrefixedPhone } from '@app/core/tools/isIndexPrefixedPhone'

/**
 * @module LoginAuthentication
 * @description Login page
 */
function split(str, index) {
    let result = [str.slice(0, index), str.slice(index)]

    return result
}

export default {
    initAction: function () {
        let LoginAuthentication = {
            onComponentCreate: (props) => (state, actions) => {
                if (isDefined(props.directAccess)) {
                    actions._setState({
                        key: 'directAccess',
                        value: props.directAccess,
                    })
                    actions._setState({ key: 'loginStep', value: 'login' })
                }
                //
                if (props?.match?.params) {
                    let patientBirthdate = props.match.params.ymd
                    if (patientBirthdate) {
                        actions._setValues({ birthdate: patientBirthdate })
                        actions._setValidity({ birthdate: true })
                    }
                    //
                    let idSurvey = props.match.params.idSurvey
                    if (isDefined(idSurvey) && idSurvey !== false) {
                        actions._setState({
                            key: 'medicalStudysurvey',
                            value: idSurvey,
                        })
                    }
                    //
                    let idPatient = props.match.params.idPatient
                    if (isDefined(idPatient) && idPatient !== false) {
                        actions._setState({
                            key: 'idPatient',
                            value: idPatient,
                        })
                    }
                    //
                    let customDisplay = getQueryVariable('display', false)
                    if (customDisplay && customDisplay !== false) {
                        sessionStorage.setItem('customDisplay', customDisplay)
                    }
                    //
                    let updateAnswer = getQueryVariable('upanswer', false)
                    if (updateAnswer && updateAnswer !== false) {
                        sessionStorage.setItem('updateAnswer', updateAnswer)
                    }
                    let updateAnswerId = getQueryVariable('upanswerid', false)
                    if (updateAnswerId && updateAnswerId !== false) {
                        sessionStorage.setItem('updateAnswerId', updateAnswerId)
                    }
                    //
                    let medicalStudytoken = props.match.params.medicalStudytoken
                    if (medicalStudytoken && medicalStudytoken !== false) {
                        localStorage.setItem(
                            'medicalStudytoken',
                            medicalStudytoken
                        )
                    }
                    //
                    let token = props.match.params.token
                    if (isDefined(token) && token !== false) {
                        localStorage.setItem(
                            'apitoken',
                            decodeURIComponent(token)
                        )
                        localStorage.setItem('expiration', new Date())
                        localStorage.setItem('medicalStudysurvey', idSurvey)
                        localStorage.setItem('idPatient', idPatient)
                        redirectToOnboarding()
                        return false
                    }
                    //
                    if (isDefined(medicalStudytoken)) {
                        actions._setState({
                            key: 'medicalStudytoken',
                            value: medicalStudytoken,
                        })
                        if (medicalStudytoken !== 'medicalStudytoken') {
                            let timelineId = props.match.params.timelineId
                            if (isDefined(timelineId) && timelineId !== false) {
                                Cardline.getMedicalInfo(medicalStudytoken).then(
                                    (res) => {
                                        actions._setState({
                                            key: 'medicalInfos',
                                            value: res.data,
                                        })
                                        props.setMedicalInfos(res.data)
                                        if (isDefined(patientBirthdate)) {
                                            actions.createSession()
                                        } else if (customDisplay === 'popup') {
                                            actions.createDoctorSession()
                                        }
                                    }
                                )
                            } else {
                                Cardline.getMedicalInfo(medicalStudytoken).then(
                                    (res) => {
                                        actions._setState({
                                            key: 'medicalInfos',
                                            value: res.data,
                                        })
                                        props.setMedicalInfos(res.data)
                                    }
                                )
                            }
                        }
                    }
                    if (props.match.params.timelineId) {
                        actions._setState({
                            key: 'timelineId',
                            value: props.match.params.timelineId,
                        })
                    }
                }
                //
                if (isDefined(props.withWelcome)) {
                    actions._setState({
                        key: 'displayWelcome',
                        value: props.withWelcome,
                    })
                }
                //
                const invitToken = getQueryVariable('invitToken', false)
                if (invitToken && invitToken !== false) {
                    actions._setState({
                        key: 'invitToken',
                        value: decodeURIComponent(invitToken),
                    })
                }
                //
                actions._setState({ key: 'customer', value: props.customer })
                actions.initLogin(props)
            },
            onComponentUpdate: (props) => (state, actions) => {
                if (state.formSubmittedOnce) {
                    /*
                    best way to retrieve error message in the good language doing as little editing as possible
                    */
                    for (const errorMessage in state.errorMessages) {
                        if (state.errorMessages[errorMessage]) {
                            actions.retrieveErrorMessage(errorMessage)
                        }
                    }
                }
            },
            /**
             * Initialize login page, clear storage and set <enter> keyboard key listener
             * @function LoginAuthentication:initLogin
             * @param {object} props - /
             */
            initLogin: (props) => (state, actions) => {
                if (props.send) {
                    state.sendToParent = props.send
                }

                window.onkeyup = function (e) {
                    const key = e.keyCode ?? e.which
                    if (key === 13) {
                        e.preventDefault()
                        e.stopPropagation()
                        actions.onSubmit(e)
                        return false
                    }
                }
            },
            /**
             * Submit login form, validation and error message displaying
             * @function LoginAuthentication:onSubmit
             */
            onSubmit: (event) => (state, actions) => {
                event.preventDefault()
                event.stopPropagation()

                if (state.formIsSubmitting) {
                    return
                }
                actions._setTypingFalse()
                actions.retrieveErrorMessage(null)
                if (state.loginStep === 'login') {
                    if (
                        (!state.directAccess && state.isValid.birthdate) ||
                        (state.directAccess &&
                            state.isValid.birthdate &&
                            state.isValid.phoneOrEmail)
                    ) {
                        actions.createSession(event)
                    } else {
                        //
                    }
                }
            },
            /**
             * Retrieve error directly from input or when submitting form
             * @function LoginAuthentication:retrieveErrorMessage
             */
            retrieveErrorMessage: (target) => (state, actions) => {
                if (target && target.target) {
                    target = target.target.getAttribute('name')
                }
                actions._setState({ key: 'formSubmittedOnce', value: true })
                if (state.loginStep === 'login') {
                    if (
                        state.isValid.email &&
                        state.isValid.birthdate &&
                        state.isValid.phoneOrEmail
                    ) {
                        actions._setErrorMessages({ email: '' })
                        actions._setErrorMessages({ birthdate: '' })
                        actions._setErrorMessages({ phoneOrEmail: '' })
                    } else {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: false,
                        })
                        if (!isDefined(target) || target === 'email') {
                            if (state.values.email === '') {
                                actions._setErrorMessages({
                                    email: t(
                                        'saisissez votre adresse email habituelle',
                                        { ns: 'login' }
                                    ),
                                })
                                actions._setTyping({ email: false })
                            } else if (!state.isValid.email) {
                                actions._setErrorMessages({
                                    email: t(
                                        "l'email renseigné n'est pas valide",
                                        { ns: 'login' }
                                    ),
                                })
                                actions._setTyping({ email: false })
                            } else {
                                actions._setErrorMessages({ email: '' })
                            }
                        }
                        if (!isDefined(target) || target === 'birthdate') {
                            if (state.values.birthdate === '') {
                                actions._setErrorMessages({
                                    birthdate: t(
                                        'saisissez votre date de naissance',
                                        { ns: 'login' }
                                    ),
                                })
                                actions._setTyping({ birthdate: false })
                                actions._setValidity({ birthdate: false })
                            } else if (!state.isValid.birthdate) {
                                actions._setErrorMessages({
                                    birthdate: t(
                                        'saisissez une date de naissance valide',
                                        { ns: 'login' }
                                    ),
                                })
                                actions._setTyping({ birthdate: false })
                            } else {
                                actions._setErrorMessages({ birthdate: '' })
                            }
                        }
                        if (!isDefined(target) || target === 'phoneOrEmail') {
                            if (state.values.phoneOrEmail === '') {
                                actions._setErrorMessages({
                                    phoneOrEmail: t(
                                        'saisissez un numéro de téléphone ou un email',
                                        { ns: 'login' }
                                    ),
                                })
                                actions._setTyping({ phoneOrEmail: false })
                            } else if (!state.isValid.phoneOrEmail) {
                                if (
                                    !isIndexPrefixedPhone(
                                        state.values.phoneOrEmail
                                    )
                                ) {
                                    actions._setErrorMessages({
                                        phoneOrEmail: t(
                                            'login.phone.phonePrefix.missing',
                                            { ns: 'login' }
                                        ),
                                    })
                                } else {
                                    actions._setErrorMessages({
                                        phoneOrEmail: t(
                                            'votre identifiant n’est pas reconnu ou mal formaté.',
                                            { ns: 'login' }
                                        ),
                                    })
                                }
                                actions._setTyping({ phoneOrEmail: false })
                            } else {
                                actions._setErrorMessages({ phoneOrEmail: '' })
                            }
                        }
                    }
                }
            },
            createDoctorSession: () => (state, actions) => {},
            askForMonitoringConsent: (asking) => (state, actions) => {
                let customDisplay = getQueryVariable('display', false)
                let updateLanguageCallback = (patient) => {
                    // send language to API
                    const locale = getLocalStoredLocale()
                    sendLanguageToApi(locale.lang).then(() => {
                        if (
                            patient.remoteMonitoringConsent === true ||
                            patient.userTheraflow.doctor.customer
                                .remoteMonitoringConsent === false ||
                            customDisplay === 'popup' || // no monitoring consent from popup (mobile webview)
                            state.customer !== 'resilience' // no monitoring consent for other than resilience
                        ) {
                            actions.redirectUserToGoodIndex()
                        } else {
                            actions._setState({
                                key: 'askForMonitoringConsent',
                                value: asking,
                            })
                        }
                    })
                }
                getUser(updateLanguageCallback, true)
            },
            refuseMonitoring: () => (state, actions) => {
                actions._setState({
                    key: 'refusedMonitoring',
                    value: true,
                })
                let targetEl = document.querySelector('div.authentication')
                scrollTo(targetEl, 0, 240)
                //
                User.acceptMonitoringConsent(false)
            },
            redirectUserToGoodIndex:
                (withconsent = false) =>
                () => {
                    if (withconsent) {
                        User.acceptMonitoringConsent(true).then(() => {
                            redirectToGoodIndex()
                        })
                    } else {
                        redirectToGoodIndex()
                    }
                },
            /**
             * Send to [createSession]{@link module:LoginAuthentication~LoginAuthentication:createSessionAgain}) or try to open session using <code> (first daily login), then, if success, call redirectToGoodIndex
             * @function LoginAuthentication:createSession
             */
            createSession: () => (state, actions) => {
                if (state.formIsSubmitting) {
                    return false
                }
                //
                localStorage.removeItem('adminuser')
                localStorage.removeItem('apitoken')
                localStorage.removeItem('user')
                localStorage.removeItem('expiration')
                localStorage.removeItem('refresh')
                //
                let birthdate = state.values.birthdate.replace(/-/g, '')
                actions._setState({ key: 'formIsSubmitting', value: true })
                //
                if (
                    state.directAccess === true ||
                    isDefined(state.invitToken)
                ) {
                    let phoneOrEmail = state.values.phoneOrEmail
                    let lighData = { birthdate: birthdate }
                    //
                    if (isDefined(state.invitToken)) {
                        lighData.invitToken = state.invitToken
                    } else {
                        if (isEmail(phoneOrEmail)) {
                            lighData.email = phoneOrEmail
                        } else {
                            let definedPhonePrefix = '+33'
                            if (
                                phoneOrEmail.length > 10 &&
                                phoneOrEmail.indexOf('+') > -1
                            ) {
                                let splittedPhone = split(phoneOrEmail, 3)
                                //
                                lighData.phone =
                                    splittedPhone[1].length === 10
                                        ? splittedPhone[1].substring(1)
                                        : splittedPhone[1]
                                lighData.phonePrefix = splittedPhone[0]
                            } else {
                                lighData.phone =
                                    phoneOrEmail.length === 10
                                        ? phoneOrEmail.substring(1)
                                        : phoneOrEmail
                                lighData.phonePrefix = definedPhonePrefix
                            }
                        }
                    }
                    //
                    Session.createMedicalLightSession(lighData).then((res) => {
                        if (
                            isDefined(res.data) &&
                            !isDefined(res.data.message)
                        ) {
                            let sessionsData = res.data
                            localStorage.setItem('apitoken', sessionsData.token)
                            localStorage.setItem(
                                'refresh',
                                sessionsData.refresh
                            )
                            localStorage.setItem(
                                'expiration',
                                sessionsData.expiration.date
                            )
                            localStorage.setItem('timelineId', state.timelineId)
                            localStorage.setItem(
                                'medicalStudysurvey',
                                state.medicalStudysurvey
                            )
                            localStorage.setItem(
                                'directAccess',
                                state.directAccess
                            )
                            //
                            actions.askForMonitoringConsent(true)
                            return false
                        } else {
                            actions._setErrorMessages({
                                global: t(
                                    'la combinaison identifiant / date de naissance est incorrecte.',
                                    { ns: 'login' }
                                ),
                            })
                            actions._setState({
                                key: 'formIsSubmitting',
                                value: false,
                            })
                        }
                    })
                } else {
                    // if queryparam display=popup is passed, than means that we come from the webview or the impersonation
                    // in this case we should specify it to the backend to avoid sending LOGGED_IN BI event for example
                    let customDisplay = getQueryVariable('display', false)
                    let sessionAlreadyCreated = customDisplay === 'popup'
                    Session.createMedicalSession(
                        birthdate,
                        state.medicalStudytoken,
                        sessionAlreadyCreated
                    ).then((res) => {
                        if (
                            isDefined(res.data) &&
                            !isDefined(res.data.message)
                        ) {
                            let sessionsData = res.data
                            localStorage.setItem('apitoken', sessionsData.token)
                            localStorage.setItem(
                                'refresh',
                                sessionsData.refresh
                            )
                            localStorage.setItem(
                                'expiration',
                                sessionsData.expiration.date
                            )
                            localStorage.setItem('timelineId', state.timelineId)
                            localStorage.setItem(
                                'medicalStudysurvey',
                                state.medicalStudysurvey
                            )
                            localStorage.setItem(
                                'directAccess',
                                state.directAccess
                            )
                            //
                            actions.askForMonitoringConsent(true)
                            return false
                        } else {
                            actions._setErrorMessages({
                                birthdate: t(
                                    'saisissez une date de naissance valide',
                                    { ns: 'login' }
                                ),
                            })
                            actions._setValidity({ birthdate: false })
                            actions._setState({
                                key: 'formIsSubmitting',
                                value: false,
                            })
                            actions._setTyping({ birthdate: false })
                        }
                    })
                }
            },
            /**
             * Redirect to the recovery page
             * @function LoginAuthentication:gotoResetPassword
             */
            gotoResetPassword: () => (state, actions) => {
                window.location.href = addCSRFToken(
                    allRoutes['index.reset'].pathname
                )
            },
        }
        LoginAuthentication = { ...coreFn, ...LoginAuthentication }
        return LoginAuthentication
    },
}
