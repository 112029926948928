import { isDefined } from './isDefined';
import { logoutAndRedirect } from './logoutAndRedirect';
import { refreshSession } from '@app/api/controlleur';
import { User } from '@app/api';
import * as constants from '@app/constants';

const getUser = function(callback, force = false, withHeader = true, redirectToLogout = true) {
    let sessionUser = localStorage.getItem('user');
    if (!isDefined(sessionUser) || (sessionUser === 'false') || force) {
        let token = localStorage.getItem('apitoken');
        if (isDefined(token)) {
            User.getUser(withHeader).then((res) => {
                if (res !== 'aborted') {
                    let newSessionUser = res.data;
                    if (isDefined(newSessionUser)) {
                        if (isDefined(newSessionUser.media) && isDefined(newSessionUser.media.url)) {
                            // default media is handle by front, remove it to get default media from api (defined in superadmin)
                            if ((newSessionUser.media.name == 'DEFAULT') || (newSessionUser.media.default === true)) {
                                newSessionUser.media.url = null;
                            }
                        }
                        //
                        localStorage.setItem('user', JSON.stringify(newSessionUser));
                        if (callback !== null) {
                            callback(newSessionUser);
                        }
                    } else {
                        refreshSession((isSuccess) => {
                            if (isSuccess !== 'aborted') {
                                if (isSuccess === 'success') {
                                    getUser(callback, force, withHeader);
                                } else if (redirectToLogout) {
                                    logoutAndRedirect();
                                } else {
                                    callback(newSessionUser);
                                }
                            }
                        }, false);
                    }
                }
            });
        } else {
            refreshSession((isSuccess) => {
                if (isSuccess !== 'aborted') {
                    if (isSuccess === 'success') {
                        getUser(callback, force, withHeader);
                    } else if (redirectToLogout) {
                        logoutAndRedirect();
                    } else {
                        callback(null);
                    }
                }
            }, false);
            // must be refreshed here !!!!
        }
    } else {
        if (callback !== null) {
            callback(JSON.parse(sessionUser));
        }
    }
}
const tryToGetUser = function(callback, force = false, withHeader = true) {
    let sessionUser = localStorage.getItem('user');
    if ((sessionUser === null) || (sessionUser === undefined) || (sessionUser === 'false') || force) {
        let token = localStorage.getItem('apitoken');
        if (isDefined(token)) {
            User.getUserWithNoRedirect().then((res) => {
                sessionUser = (isDefined(res) ? res.data : null);
                if (isDefined(sessionUser) && isDefined(sessionUser.media) && isDefined(sessionUser.media.url)) {
                    // default media is handle by front, remove it to get default media from api (defined in superadmin)
                    if ((sessionUser.media.name == 'DEFAULT') || (sessionUser.media.default === true)) {
                        sessionUser.media.url = null;
                    }
                }
                localStorage.setItem('user', JSON.stringify(sessionUser));
                if (callback !== null) {
                    callback(sessionUser);
                }
            });
        } else {
            localStorage.removeItem('user');
        }
    } else {
        if (callback !== null) {
            callback(JSON.parse(sessionUser));
        }
    }
}
const getSupport = function(key = 'mailto') {
    let support = ' support@theraflow.care';
    let customerObj = constants.custo.CUSTOMERS[process.env.APP_CUSTOMISATION.toLowerCase()];
    if (isDefined(customerObj) && isDefined(customerObj[key])) {
        support = customerObj[key];
    } else if (key === 'whole') {
        support = customerObj;
    }
    //
    return support;
}

export { getUser, tryToGetUser, getSupport };