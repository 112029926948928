import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { API_URL } = settings;

function getStudies() {
    return queueQueries(`${API_URL}/medical-study/`, 'GET');
}
function closeSurvey(id, studyid) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/${id}/close-alert/${studyid}/`, 'PUT');
}
function getStudy(id) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/${id}/`, 'GET');
}
function sendStudy(id, data) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/${id}/`, 'POST', 'application/json', true, true, true, true, true, data);
}
function sendMedicalAnswer(data) {
    return queueQueries(`${API_URL}/medical-study/answer/`, 'POST', 'application/json', true, false, true, true, true, data);
}
function sendDoctorMedicalAnswer(userid, userMedicalStudySurveyId, data) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/${userid}/answer-survey/${userMedicalStudySurveyId}/`, 'POST', 'application/json', true, false, true, true, true, data);
}
function getDoctorMedicalDefaultAnswer(answerid) {
    return queueQueries(`${API_URL}/theraflow/medical-study-common/answer/${answerid}/`, 'GET');
}
function sendDoctorMedicalUpdateAnswer(userid, userMedicalStudySurveyId, data) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/${userid}/update-survey-answer/${userMedicalStudySurveyId}/`, 'POST', 'application/json', true, false, true, true, true, data);
}
function updateStudy(id, studyid, data) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/${id}/${studyid}/`, 'PUT', 'application/json', true, true, true, true, true, data);
}
function getMedicalSurvey(id, studyname, page) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/${id}/responses/${studyname}/${page}/1/`, 'GET');
}
function getMedicalSurveyAlerts(page, filter) {
    return queueQueries(`${API_URL}/theraflow/medical-study/user/alert/all/search/${page}/`, 'POST', 'application/json', true, true, true, true, true, filter);
}

export const Medical = {
    getStudies,
    closeSurvey,
    getStudy,
    sendStudy,
    updateStudy,
    getMedicalSurvey,
    getMedicalSurveyAlerts,
    sendMedicalAnswer,
    sendDoctorMedicalAnswer,
    getDoctorMedicalDefaultAnswer,
    sendDoctorMedicalUpdateAnswer,
};