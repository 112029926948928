import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { API_URL } = settings;

function askPhone(data) {
    return queueQueries(`${API_URL}/theraflow/profile/ask-phone/`, 'POST', 'application/json', true, true, true, true, true, data);
}
function checkPhone(code) {
    return queueQueries(`${API_URL}/theraflow/profile/check-phone/`, 'POST', 'application/json', true, true, true, true, true, {code: code});
}
function updatePhone(answer, code) {
    return queueQueries(`${API_URL}/theraflow/profile/do-update-phone/`, 'POST', 'application/json', true, true, true, true, true, {answer: answer, code: code});
}
function savePhone(id, data) {
    return queueQueries(`${API_URL}/user/save-phone/${id}/`, 'POST', 'application/json', true, true, true, false, true, data);
}
function validatePhone(id, data) {
    return queueQueries(`${API_URL}/user/validate-phone/${id}/`, 'POST', 'application/json', true, true, true, false, true, data);
}

export const Phone = {
    askPhone,
    checkPhone,
    updatePhone,
    savePhone,
    validatePhone
};