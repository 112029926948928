import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { API_URL } = settings;

function createSession(email, password) {
    return queueQueries(`${API_URL}/session/`, 'POST', 'application/json', true, true, true, false, true, {email: email, password: password}, true);
}
function openSession(code, password, token) {
    return queueQueries(`${API_URL}/session/`, 'PUT', 'application/json', true, true, true, false, true, {code: code, password: password, token: token}, true);
}
function destroySession() {
    return queueQueries(`${API_URL}/session/`, 'DELETE', null, false, true);
}
function resetPwd() {
    return queueQueries(`${API_URL}/session/new-password/`, 'GET');
}
function createMedicalSession(birthdate, medicalStudytoken, sessionAlreadyCreated) {
    return queueQueries(`${API_URL}/session/medical-study/`, 'POST', 'application/json', true, false, true, false, true, {birthdate: birthdate, medicalStudyToken: medicalStudytoken, sessionAlreadyCreated: sessionAlreadyCreated}, true, true)
}
function createMedicalLightSession(lightData) {
    return queueQueries(`${API_URL}/session/light-session/`, 'POST', 'application/json', true, false, true, false, true, lightData, true, true)
}

export const Session = {
    createSession,
    openSession,
    destroySession,
    resetPwd,
    createMedicalSession,
    createMedicalLightSession,
};